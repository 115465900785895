import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, Button, Divider,Spin, Typography, notification,Avatar, Drawer, Space, Form, Input, InputNumber, Select, Checkbox, DatePicker, Modal, message, Table, AutoComplete, Switch } from "antd";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import { UserOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import '../../assets/styles/custom_style.css';
import UserModalContent from './UserModalContent';
import UserPaymentModalHistory from './UserPaymentModalHistory';
import AddUserDetailsModel from './AddUserDetailsModel';
import moment from 'moment';
import _ from 'lodash';
import userDummy from "../../assets/images/dummy-user.png";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

function PaymentsForm() {
    const { id } = useParams(); // Extract id from URL
    const history = useHistory();
    const [form] = Form.useForm();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [file, setFile] = useState(null);  // state to store the selected file
    const [userBasicDetail, setuserBasicDetail] = useState({});  // state to store the selected file
    const [userId, setUserId] = useState(null);
    const [profileImg, setProfileImg] = useState(null);
    const [isModalForConfirmation, setIsModalForConfirmation] = useState(false);
    const [checkedOption, setCheckedOption] = useState('');
    // const [updateNumber, setUpdateNumber] = useState();
    const [alternativeNumber1, setAlternativeNumber1] = useState(null);
    const [alternativeNumber2, setAlternativeNumber2] = useState(null);
    const [phoneNumberUse, setPhoneNumberUse] = useState(null);
    const [primaryId, setPrimaryId] = useState('');
    const [childrenId, setChildrenId] = useState(null);
    const [isModalAddMember, setIsModalAddMember] = useState(false);
    const [childrenList, setChildrenList] = useState([]);
    //For model show data
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleImg, setIsModalVisibleImg] = useState(false);
    const [addedUserData, setAddedUserData] = useState(null);
    const [paymentUserData, setpaymentUserData] = useState(null);
    const [paidMonths, setPaidMonths] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showMonthCoverd, setshowMonthCoverd] = useState(true);
    const [lastPayment, setLastPayment] = useState('');
    const [loading, setLoading] = useState(false); // State to manage the loade


    const [showSpecialOccassionCategory, setSpecialOccassionCategory] = useState(false);
    const [showCategoryPay, setCategoryPay] = useState(true);
    const [countryCode, setCountryCode] = useState('91');
    const [UserPaymentHistory, setUserPaymentHistory] = useState(false); // State to manage the visibility of the modal
    const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);
    const [AddEditUserDetailsForm, setAddEditUserDetailsForm] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('pending');
    const [optionSuggestion, setOptionSuggestion] = useState([]);
    const [transactionDate, setTransactionDate] = useState(moment().format('YYYY-MM-DD'));
    const [switchChecked, setSwitchChecked] = useState(false);
    const [showEReceiptFields, setShowEReceiptFields] = useState(false);
    const [ereciept_date, setEreciept_date] = useState();
    const [action, setAction] = useState('save'); // State to track the action
    const [userComment, setUserComment] = useState('');


    useEffect(() => {
        // Check if id exists to determine if it's an update mode
        if (id) {
            setIsUpdateMode(true);
            fetchUserDetails();
        }

    }, [id]);

    useEffect(() => {
        const fetchPaidMonths = async () => {
            try {
                setPaidMonths([])
                const months = await getPaidMonths(userId);
                // setPaidMonths(months);
            } catch (error) {
                message.error('Failed to fetch paid months');
            }
        };

        fetchPaidMonths();
    }, [userId]);


    useEffect(() => {
        const getLastPayment = async () => {
            try {

                await getLastPaymentDetail(userId);

            } catch (error) {
                message.error('Failed to fetch paid months');
            }
        };

        getLastPayment();
    }, [userId]);


    const getLastPaymentDetail = async (userId) => {
        if (userId) {
            const response = await axios.get(
                `${baseUrl}/payments/get-last-payment?userId=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                }
            );
            if (response.status === 200) {
                setLastPayment(response.data.result);
            } else {
                // message.success("This is a new user. Please add their payment details so the system can create them automatically.");

            }
        }
    };





    const fetchUserDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/payments/read/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                // setRoleData(response.data.result);
                const userData = response.data.result;
                setTransactionDate(userData.transaction_date ? moment(userData.transaction_date) : null);
                userData.payment_entry_date = userData.payment_entry_date ? moment(userData.payment_entry_date) : null;
                form.setFieldsValue(userData);

                if (userData.profile_img) {
                    setImageUrl(userData.profile_img);
                }

            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const onFinish = async (values) => {

        setLoading(true);  
        try {
            values.is_special_occassion = values.is_special_occasion ? 1 : 0;  // or values.is_special_occassion ? "yes" : "no"

            const formData = new FormData();

            Object.keys(values).forEach(key => {
                formData.append(key, values[key] !== undefined ? values[key] : '');
            });
            if (file) {
                formData.append("file", file);
            }

            if (userId) {
                formData.append("userId", userId);
            }
            if (transactionDate) {
                formData.append("transaction_date", transactionDate);
            }

            if (countryCode) {
                formData.append("country_code", countryCode);
            }

            if (action) {
                formData.append("action", action);
            }


            if (ereciept_date) {
                const formattedDate = moment(ereciept_date).format('YYYY-MM-DD');
                formData.append("e_reciept_date", formattedDate);
            }

            const response = await axios.post(`${baseUrl}/payments/create`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                setLoading(false); 
                notification.success({
                    message: 'Success',
                    description: 'Payment added successfully!',
                    placement: 'topRight'
                });
                form.resetFields();
                if (action === 'save') {
                    // Redirect to view payment details if "Save/View Payment" is clicked
                    history.push(`/payments/view/${response.data.data.user.id}`);
                } else if (action === 'next') {
                    // If "Save/Next Payment" is clicked, keep the form ready for the next payment
                    //history.push(`/payments/add_user_payment`);  // Or keep the form on the same page
                    window.location.reload();
                }
                else if (action === 'not_send') {
                    // If "Save/Next Payment" is clicked, keep the form ready for the next payment
                    //history.push(`/payments/add_user_payment`);  // Or keep the form on the same page
                    window.location.reload();
                }
                

            } else {
                setLoading(false); 
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
            // }
        } catch (error) {
            setLoading(false); 
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    // Callback function to handle ID passed from sub-component
    const handleIdChange = (id) => {
        setPrimaryId(null);
        setUserId(id);
        setChildrenId(id);
    };

    const getPaidMonths = async (userId) => {
        if (userId) {
            const response = await axios.get(
                `${baseUrl}/payments/overlap-check?userId=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                }
            );
            if (response.status === 200) {
                const paidMonths = response.data.result.map(date => moment(date, 'YYYY-MM-DD').add(15, 'days'));
                setPaidMonths(paidMonths);

                if (response.data?.today?.length > 0) {
                    message.error("Today date payment already exit.");
                }

            } else {
                // message.success("This is a new user. Please add their payment details so the system can create them automatically.");

            }
        }
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setAddedUserData(null);
        history.push('/payments');
    };

    const handleModalForConfirmationClose = () => {
        setIsModalForConfirmation(false);
    };

    const handleModalNewMemberClose = () => {
        setIsModalAddMember(false);
    };

    const handlePhoneNumberChange = async (e) => {
        const value = e.target.value;
        if (/^\d{0,12}$/.test(value)) {
            setPhoneNumber(value);
            if (value.length >= 10) {
                setPhoneNumberUse(value);
                await checkPhoneNumber(value); // Call the async function to check the phone number
            }
        }
    }

    const checkPhoneNumber = async (phone, type = '') => {
        try {
            setIsModalForConfirmation(false);
            setAlternativeNumber2(null);
            setAlternativeNumber1(null);
            const response = await axios.post(
                `${baseUrl}/payments/checkUserPhone`,
                { phone_no: phone },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                }
            );
            if (response.status === 200 && response.data.exists) {

                 setProfileImg(response.data.exists.profile_img);

                getLastPaymentDetail(response.data.details.user_id);
                form.setFieldsValue({
                    comment: response.data.details?.payment_comment || '',
                })
                // setUserComment(response.data.details.payment_comment)
                message.success('This is an already existing user. Please add their payment details.');
                console.log("response.data?.children?.length", response.data?.children?.length);
                if (response.data?.children?.length > 0) {
                    // Combine details array with children object
                    const combinedList = [response.data.details, ...response.data.children];
                    // Update the state with the combined list
                    setChildrenList(combinedList);
                    // setChildrenList(...response.data.details, response.data.children);
                }




                setSwitchChecked(response.data.details.status)
                if (phone === response.data.details.alternate_phone_no) {
                    setIsModalForConfirmation(true);
                    // setUpdateNumber(phone);
                    setAlternativeNumber1(response.data.details.phone_no);
                } else if (phone === response.data.details.alternate_phone_no2) {
                    setIsModalForConfirmation(true);
                    // setUpdateNumber(phone);
                    setAlternativeNumber2(response.data.details.phone_no);
                }

                handleChangeData(response.data.details, false);

                if (type == 'view') {
                    setAddedUserData(response.data.details); // Save added payments data
                }

            } else {
                setLastPayment(null)
                setUserId(null);
                setProfileImg(null);
                setChildrenId(null);
                setChildrenList([]);
                setPrimaryId(null);
                //message.success("This is a new user. Please add their payment details so the system can create them automatically.");
                form.setFieldsValue({
                    user_id: '',
                    name_eng: '',
                    father_name_en: '',
                    profile_img: '',
                    relation_en: '',
                    father_name_hn: '',
                    name_hn: '',
                    relation_hn: '',
                    amount_announce: '',
                    duration_type: '',
                    per_village_en: '',
                    per_tahsil_en: '',
                    per_district_en: '',
                    per_village_hn: '',
                    per_tahsil_hn: '',
                    per_district_hn: '',
                    is_calendar: '',
                    no_calendar: '',
                    calendar_per_name: '',
                    is_honored: ''
                    

                });
            }
            // }
        } catch (error) {
            console.error('There was an error!', error);
            message.error('Error checking phone number');
        }
    };

    const getChildrenById = async (id, type = '') => {
        try {
            const response = await axios.post(
                `${baseUrl}/payments/checkUserById`,
                { id: id },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                }
            );
            if (response.status === 200 && response.data.exists) {
                message.success('This is an already existing user. Please add their payment details.');
                const { user_id, phone_no, name_eng, father_name_en, relation_en, name_hn, relation_hn, father_name_hn, announced_amount, announced_type, announced_for_year, alternate_phone_no, alternate_phone_no2,profile_img } = response.data.details;

                setUserId(user_id);
                setProfileImg(profile_img);
                form.setFieldsValue({
                    user_id: user_id,
                    name_eng: name_eng,
                    father_name_en: father_name_en, 
                    relation_en: relation_en,
                    father_name_hn: father_name_hn,
                    name_hn: name_hn,
                    relation_hn: relation_hn,
                    month_share_amount: announced_amount,
                    frequency_type: announced_type,
                    announced_pay_year: announced_for_year
                });

                if (type == 'view') {
                    setAddedUserData(response.data.details); // Save added payments data
                }

            } else {
                setUserId(null);
                setProfileImg(null);
                //message.success("This is a new user. Please add their payment details so the system can create them automatically.");
                form.setFieldsValue({
                    user_id: '',
                    name_eng: '',
                    father_name_en: '',
                    profile_img: '',
                    relation_en: '',
                    father_name_hn: '',
                    name_hn: '',
                    relation_hn: '',
                    amount_announce: '',
                    duration_type: '',
                    per_village_en: '',
                    per_tahsil_en: '',
                    per_district_en: '',
                    per_village_hn: '',
                    per_tahsil_hn: '',
                    per_district_hn: '',
                    is_calendar: '',
                    no_calendar: '',
                    calendar_per_name: '',
                    is_honored: ''
                    
                    

                });
            }
        } catch (error) {
            console.error('There was an error!', error);
            message.error('Error checking phone number');
        }
    };

    const checkPhoneNumberView = async (phone, type = '') => {
        try {
            setAddEditUserDetailsForm(null)
            setpaymentUserData(null);

            console.log("childrenId", childrenId);
            const response = await axios.post(
                // `${baseUrl}/payments/${childrenId ? `checkUserByIdView` : `checkUserPhoneView`}`,
                `${baseUrl}/payments/checkUserByIdView`,
                // childrenId ? { id: childrenId } : { phone_no: phone },
                { id: userId },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                }
            );
            if (response.status === 200 && response.data.exists) {
                if (type == 'view') {

                    const phone_no = form.getFieldValue('phone_no');
                    setAddEditUserDetailsForm(response.data.details, phone_no);
                    console.log("response.data.details.userid", response.data.details.userid)
                    setpaymentUserData(response.data.details.userid, phone_no);
                }

            } else {
                // message.success("This is a new user. Please add their payment details so the system can create them automatically.");

            }
        } catch (error) {
            console.error('There was an error!', error);
            message.error('Error checking phone number');
        }
    };

    const handlePaymentTypeChange = async (value) => {

        const phoneNumber = form.getFieldValue('phone_no');

        if (value === '1') {
            setshowMonthCoverd(true)

        } else {
            setshowMonthCoverd(false)
        }

    }

    const handleSpecialOccassionCategory = (e) => {
        const isChecked = e.target.checked;
        setSpecialOccassionCategory(isChecked);
        setCategoryPay(!isChecked);
    }

    const handleCountryCodeChange = (value) => {
        setCountryCode(value);
        form.setFieldsValue({ country_code: value });
    };

    const CheckPhoneUser = async () => {

        const values = await form.validateFields(['phone_no']);
        const phoneNumber = values.phone_no;
        await checkPhoneNumber(phoneNumber);

    }

    const UserPaymentHistoryHandel = async () => {
        setpaymentUserData(null);
        const values = await form.validateFields(['phone_no']);
        const phoneNumber = values.phone_no;
        if (phoneNumber.length >= 10 && userId) {
            await checkPhoneNumberView(phoneNumber, 'view');
            setUserPaymentHistory(true); // Close modal

        }

    };

    const handleUserPaymentHistoryClose = () => {
        setUserPaymentHistory(false); // Close modal
    };

    const AddUserDetails = async () => {
        setpaymentUserData(null);
        setAddEditUserDetailsForm(null);
        setuserBasicDetail(null);
        const values = await form.validateFields(['phone_no']);
        const phoneNumber = values.phone_no;


        if (phoneNumber.length >= 10 && userId) {
            await checkPhoneNumberView(phoneNumber, 'view'); // Call the async function to check the phone number
        }
        setuserBasicDetail({
            'phone_no': phoneNumber,
            'country_code': countryCode,
            'name_eng': form.getFieldValue('name_eng'),
            'relation_en': form.getFieldValue('relation_en'),
            'father_name_en': form.getFieldValue('father_name_en'), 
            'name_hn': form.getFieldValue('name_hn'),
            'relation_hn': form.getFieldValue('relation_hn'),
            'father_name_hn': form.getFieldValue('father_name_hn'),
            'per_village_en:  ': form.getFieldValue('per_village_en'),
            'per_tahsil_en': form.getFieldValue('per_tahsil_en'),
            'per_district_en': form.getFieldValue('per_district_en'),
            'per_village_hn': form.getFieldValue('per_village_hn'),
            'per_tahsil_hn': form.getFieldValue('per_tahsil_hn'),
            'per_district_hn': form.getFieldValue('per_district_hn'),
            'is_calendar': form.getFieldValue('is_calendar'),
            'no_calendar': form.getFieldValue('no_calendar'),
            'calendar_per_name': form.getFieldValue('calendar_per_name'),
            'is_honored': form.getFieldValue('is_honored')

        })
        setAddUserDetailsModel(true)
    }

    const AddUserDetailsClose = () => {
        setAddUserDetailsModel(false)
    }

    // Handle OK button click
    const handleOk = async () => {
        if (checkedOption === 'usePrimaryNumber') {

            form.setFieldsValue({
                phone_no: alternativeNumber1 ? alternativeNumber1 : alternativeNumber2,
            });
        } else if (checkedOption === 'newMember') {
            // setUpdateNumber('');
            setAlternativeNumber1('');
            setAlternativeNumber2('');
            setUserId(null);
            setProfileImg(null);
            //message.success("This is a new user. Please add their payment details so the system can create them automatically.");
            form.setFieldsValue({
                user_id: '',
                name_eng: '',
                father_name_en: '',
                profile_img: '',
                relation_en: '',
                father_name_hn: '',
                name_hn: '',
                relation_hn: '',
                amount_announce: '',
                duration_type: '',
                per_village_en: '',
                per_tahsil_en: '',
                per_district_en: '',
                per_village_hn: '',
                per_tahsil_hn: '',
                per_district_hn: '',
                is_calendar: '',
                no_calendar: '',
                calendar_per_name: '',
                is_honored: ''
            });
        }
        console.log('Checked option:', checkedOption);
        handleModalForConfirmationClose();
    };

    const monthCellRender = (date) => {
        const isPaid = paidMonths.some(
            (paidMonth) => paidMonth.year() === date.year() && paidMonth.month() === date.month()
        );

        return (
            <div style={{ backgroundColor: isPaid ? '#d4edda' : '', color: isPaid ? 'green' : 'inherit', cursor: isPaid ? 'not-allowed' : 'pointer' }}>
                {date.format('MMM')}
            </div>
        );
    };

    const disabledDate = (current) => {
        return paidMonths.some(
            (paidMonth) => current.year() === paidMonth.year() && current.month() === paidMonth.month()
        );
    };

    const debounceConvertText = useRef(_.debounce(async (text, column) => {
        try {
            const response = await axios.post(`${baseUrl}/translate`, { text });
            form.setFieldsValue({
                [column]: response.data.translatedText,
            });
        } catch (error) {
            console.error('Error translating text:', error);
        }
    }, 300)).current; // Adjust debounce delay as 

    function handleInputChange(text, column) {
        const newText = text;
        debounceConvertText(newText, column);
    };

    const addNewMember = async () => {
        if (userId) {
            handleModalNewMemberClose();
            setuserBasicDetail({})
            setAddEditUserDetailsForm(null)
            setPrimaryId(userId);
            // setUpdateNumber('');
            setAlternativeNumber1('');
            setAlternativeNumber2('');
            setpaymentUserData(null);
            setUserId(null);
            setProfileImg(null);
            //message.success("This is a new user. Please add their payment details so the system can create them automatically.");
            form.setFieldsValue({
                user_id: '',
                name_eng: '',
                father_name_en: '',
                profile_img: '',
                relation_en: '',
                father_name_hn: '',
                name_hn: '',
                relation_hn: '',
                month_share_amount: '',
                announced_pay_year: '',
                frequency_type: '',
                per_village_en: '',
                per_tahsil_en: '',
                per_district_en: '',
                per_village_hn: '',
                per_tahsil_hn: '',
                per_district_hn: '',
                is_calendar: '',
                no_calendar: '',
                calendar_per_name: '',
                is_honored: ''
            });
        }
    }

    const columnsChildren = [
        {
            title: "Member Name",
            dataIndex: "name_eng",
            key: "name_eng",
            fixed: 'left'
        },
        {
            title: "Relation",
            dataIndex: "relation_en",
            key: "relation_en",
            fixed: 'left'
        },
        {
            title: "Father Name",
            dataIndex: "father_name_en",
            key: "father_name_en",
            fixed: 'left'
        },

        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Button
                    onClick={() => handleChangeData(record, true)}
                    type={record.user_id === userId ? 'primary' : 'default'}
                    icon={record.user_id === userId ? <CheckCircleOutlined /> : null}
                    style={record.user_id === userId ? { backgroundColor: 'green', borderColor: 'green', color: 'white' } : {}}
                >
                    {record.user_id === userId ? 'Selected' : 'Add Payment'}
                </Button>
            ),
        }
    ];

    const fetchChildrenMember = async (userId) => {
        addNewMember();
        // try {
        //     const response = await axios.get(
        //         `${baseUrl}/users-children/read/${userId}`,
        //         {
        //             headers: {
        //                 Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        //             },
        //         }
        //     );
        //     if (response.status === 200) {
        //         console.log(response.data);
        //         if (response.data.result.length === 0) {
        //             addNewMember();
        //         } else {
        //             setIsModalAddMember(true)
        //             setChildrenList(response.data.result);
        //         }
        //     } else {

        //     }
        // } catch (error) {
        //     console.error('There was an error!', error);
        //     message.error('Error checking phone number');
        // }
    };

    const handleChangeData = (record, isChildren) => {

        const { user_id, phone_no, name_eng, father_name_en, relation_en, name_hn, relation_hn, father_name_hn, announced_amount, announced_type, announced_for_year, alternate_phone_no, alternate_phone_no2, status, per_village_en, per_tahsil_en, per_district_en, per_village_hn, per_tahsil_hn, per_district_hn,profile_img,is_calendar,no_calendar,calendar_per_name,is_honored } = record;
        console.log("record", record);
        console.log("user_id", user_id);
        setChildrenId(isChildren ? user_id : null);
        setUserId(user_id);
        setProfileImg(profile_img);
        console.log("user_id---", user_id);
        // getChildrenById(isChildren ? user_id : null);
        setSwitchChecked(status);
        // checking
        setPrimaryId(isChildren ? null : user_id);

        form.setFieldsValue({
            user_id: user_id,
            name_eng: name_eng,
            father_name_en: father_name_en,
            profile_img: profile_img,
            relation_en: relation_en,
            father_name_hn: father_name_hn,
            name_hn: name_hn,
            relation_hn: relation_hn,
            month_share_amount: announced_amount,
            frequency_type: announced_type,
            announced_pay_year: announced_for_year,
            per_village_en: per_village_en,
            per_tahsil_en: per_tahsil_en,
            per_district_en: per_district_en,
            per_village_hn: per_village_hn,
            per_tahsil_hn: per_tahsil_hn,
            per_district_hn: per_district_hn,
            is_calendar: is_calendar,
            no_calendar: no_calendar,
            calendar_per_name: calendar_per_name,
            is_honored: is_honored,

        });
    };

    // Mapping between English and Hindi values
    const relationMapping = {
        "Son of Mr.": "पुत्र श्री",
        "Daughter of Mr.": "सुपुत्री श्री",
        "Wife of Mr.": "पत्नी श्री",
        "Adopted Son of Mr.": "गोद पुत्र श्री"
    };

    // Function to handle change
    const handleRelationChange = (value) => {
        const hindiValue = relationMapping[value];
        form.setFieldsValue({
            relation_en: value,
            relation_hn: hindiValue
        });
    };

    // Set Be-Default value in payment status
    const handlePaymentStatusChange = (value) => {
        setPaymentStatus(value);
    };

    const handleDateChange = (date, dateString) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setTransactionDate(formattedDate)
            form.setFieldsValue({ tnxDate: date });
        } else {
            setTransactionDate(null);
            form.setFieldsValue({ tnxDate: null });
        }
    };


    const getPanelValue = async (searchText, columnName, tableName) => {
        if (searchText.length < 3) {
            setOptionSuggestion([]);
            return;
        }

        try {
            const response = await axios.get(`${baseUrl}/payments/getSuggestionValue/`, {
                params: { q: searchText, column: columnName, table: tableName },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                setOptionSuggestion(response.data.result);
                if (response && response.data && Array.isArray(response.data.result) && response.data.result.length > 0) {
                    // openNotification('top', response.data.result);
                }
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChangeSuggestion = (value, columnName) => {
        form.setFieldsValue({
            [columnName]: value
        });
    };

    const openNotification = (placement, data) => {
        console.log("data", data);
        notification.destroy();  // Clear existing notifications
        notification.open({
            message: 'Alert',
            description: `Transaction details (UTR/ID) already exist (${data[0].name_eng}/${data[0].phone_no})`,
            placement,
        });
    };

    const handleSwitchChange = async (checked) => {
        setSwitchChecked(checked);
        const response = await axios.patch(`${baseUrl}/users/update-announced-year/${userId}`, { status: checked }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            },
        });
        if (response.status === 200) {
            setChildrenList((prevChildrenList) =>
                prevChildrenList.map((child) =>
                    child.user_id === userId
                        ? { ...child, status: checked }
                        : child
                )
            );
            notification.info({
                message: 'Info',
                description: "Member Status Updated successfully",
                placement: 'topRight'
            });
        } else {
            notification.info({
                message: 'Info',
                description: response.message,
                placement: 'topRight'
            });
        }
        console.log("Switch checked:", checked); // Log or handle the switch value as needed
    };

    const countryCodes = [
        "91", "93", "355", "213", "376", "244", "54", "374", "297", "247", "61", "43", "994",
        "973", "880", "375", "32", "501", "229", "1,441", "975", "591", "387", "267",
        "55", "1,284", "673", "359", "226", "95", "257", "855", "237", "1", "238",
        "1,345", "236", "235", "56", "86", "61", "61", "57", "269", "242", "682",
        "506", "385", "53", "357", "420", "243", "45", "246", "253", "1767", "593",
        "20", "503", "240", "291", "372", "251", "500", "298", "679", "358", "33",
        "594", "689", "241", "220", "995", "49", "233", "350", "30", "299", "1,473",
        "590", "1,671", "502", "224", "245", "592", "509", "39", "504", "852", "36",
        "354", "62", "98", "964", "353", "44", "972", "39", "225", "1876",
        "81", "44", "962", "7", "254", "686", "965", "996", "856", "371", "961",
        "266", "231", "218", "423", "370", "352", "853", "389", "261", "265", "60",
        "960", "223", "356", "692", "596", "222", "230", "262", "52", "691", "373",
        "377", "976", "382", "1,664", "212", "258", "264", "674", "977", "31", "599",
        "687", "64", "505", "227", "234", "683", "672", "850", "1670", "47", "968",
        "92", "680", "970", "507", "675", "595", "51", "63", "870", "48", "351",
        "974", "242", "262", "40", "7", "250", "590", "290", "1869", "1,758", "590",
        "508", "1,784", "685", "378", "239", "966", "221", "381", "248", "232", "65",
        "1,721", "421", "386", "677", "252", "27", "82", "211", "34", "94", "249",
        "597", "47", "268", "46", "41", "963", "886", "992", "255", "66", "670",
        "228", "690", "676", "1868", "216", "90", "993", "1,649", "688", "256",
        "380", "971", "44", "1", "598", "1340", "998", "678", "58", "84", "681",
        "212", "967", "260", "263"
    ];

    const handlePaymentModeChange = (value) => {
        // Show E-Receipt fields if the payment mode is Cash, Website-Rozapay, or Application-Rozapay
        const modesToShow = ['Cash', 'Website-Rozapay', 'Application-Rozapay'];
        setShowEReceiptFields(modesToShow.includes(value));
    };

    const handleReciptDateChange2 = (date, dateString) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setEreciept_date(formattedDate)
        } else {
            setEreciept_date(null);
        }
    };

    const handleReciptDateChange3 = (date, dateString) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setEreciept_date(formattedDate)
            form.setFieldsValue({ ereciept_date: date });
        } else {
            setEreciept_date(null);
            form.setFieldsValue({ ereciept_date: null });
        }

    };

    const handleReciptDateChange = (date, dateString) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setEreciept_date(formattedDate)
        } else {
            setEreciept_date(null);
        }
    };

    // Save user comment
    const handleSaveComment = async () => {
        if (!phoneNumberUse) {
            notification.info({
                message: 'Info',
                description: 'Please add user phone no.',
                placement: 'topRight'
            });
            return;
        }else if (!userId) {
            notification.info({
                message: 'Info',
                description: 'Please add user then add comment',
                placement: 'topRight'
            });
            return;
        }else if (!userComment) {
            notification.info({
                message: 'Info',
                description: 'Comment is required',
                placement: 'topRight'
            });
            return;
        }
        const response = await axios.patch(`${baseUrl}/users/update-comment/${userId}`, { comment: userComment }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            },
        });
        if (response.status === 200) {
            notification.info({
                message: 'Info',
                description: "Comment updated successfully",
                placement: 'topRight'
            });
        } else {
            notification.info({
                message: 'Info',
                description: response.message,
                placement: 'topRight'
            });
        }
    };


    //For show image on model larges
 
    const [currentImage, setCurrentImage] = useState("");
  
    const showModal = (image) => {
      setCurrentImage(image || userDummy);
      setIsModalVisibleImg(true);
    };
  
    const handleCancel = () => {
      setIsModalVisibleImg(false);
    };

    return (
        <>

        {loading && (
            <div className="full-page-loader">
              <Spin size="large" />
            </div>
          )}
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title="Add Payment"
                    >
                        <Form style={{ "padding": "20px" }} form={form}
                            onFinish={onFinish} layout="vertical"
                            initialValues={{ bank_account: "1", payment_virify: "pending", pyament_mode_type: "UPI", tnxDate: moment() }} >
                            <Row gutter={[12, 12]}>
                                <Col xs={24}>
                                    <Row gutter={[12, 12]}>
                                        <Col xs={12}>
                                            <Item className="phone_no custom-form-item" label="Phone No." name="phone_no"
                                                rules={[{ required: true, message: 'Please enter phone no' },
                                                {
                                                    pattern: /^\d{10,12}$/,
                                                    message: 'Phone no must be between 10 and 12 digits!',
                                                }
                                                ]} >
                                                <Input className="PhoneInputs" onChange={handlePhoneNumberChange} placeholder="Phone no."
                                                    addonBefore={
                                                        <Select
                                                            defaultValue={countryCode}
                                                            onChange={handleCountryCodeChange}
                                                            style={{ width: 80 }}
                                                        >
                                                            {countryCodes.map((code) => (
                                                                <Option key={code} value={code}>+{code}</Option>
                                                            ))}
                                                            {/* Add more country codes as needed */}
                                                        </Select>
                                                    }
                                                    prefix={<PhoneOutlined />}
                                                    addonAfter={
                                                        <div>
                                                            <Button className="ant-btn-primary search-add-button" onClick={CheckPhoneUser}>Search/Add New</Button>
                                                            {userId && (
                                                                childrenId ? '' :
                                                                    <Button className="ant-btn-primary search-add-button" onClick={() => fetchChildrenMember(userId)}>New Member</Button>
                                                            )}

                                                        </div>
                                                    }
                                                />
                                            </Item>
                                        </Col>

                                        {profileImg && ( 
                                            <Col xs={2}>
                                            <Item className="custom-form-item" label="Image" name="profileImg" > 
                                            <Avatar
                                            className="shape-avatar"
                                            shape="square"
                                            size={40}
                                            src={profileImg ? profileImg : userDummy}
                                            icon={!profileImg && <UserOutlined />}
                                            onClick={() => showModal(profileImg ? profileImg : userDummy)}
                                            style={{ cursor: 'pointer',marginTop : '5px' }}
                                            /> 
                                            </Item>
                                       
                                        </Col>
                                       )}

                                       

                                        
                                        <Col xs={10}>
                                            {/* {userId && */}
                                            <Item className="phone_no custom-form-item comment-field" label="Comment" name="comment"
                                                rules={[{ required: false, message: 'Please enter comment' }]} >
                                                <Input type="text" onChange={(e) => setUserComment(e.target.value)} placeholder="Enter comment" addonAfter={
                                                    // <div>
                                                    <Button style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', color: '#fff' }} className="ant-btn-primary search-add-button" onClick={handleSaveComment}>Save Comment</Button>
                                                    // </div>
                                                } />
                                            </Item>
                                            {/* } */}
                                            {isModalForConfirmation ? <h4 style={{ "color": "red" }}>Primary Number : {alternativeNumber1 ? alternativeNumber1 : alternativeNumber2}</h4> : ''}
                                        </Col>
                                    </Row>
                                </Col>
                                {childrenList.length > 0 ?
                                    <Col xs={24}>
                                        <div className="table-responsive">
                                            <Table
                                                columns={columnsChildren}
                                                dataSource={childrenList}
                                                pagination={false}
                                                className="ant-border-space addpaymentTable"
                                            />
                                        </div>
                                    </Col> : ''}

                                <Col xs={24}>

                                    <Title level={5} style={{ display: 'inline-block', marginRight: '8px', marginBottom: 0, }}>Add User Detail</Title>

                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>- Member ID: {userId}</span>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="Name" name="name_eng" rules={[{ required: true, message: 'Please enter name' }]}>
                                        <Input placeholder="User Name" onChange={(e) => handleInputChange(e.target.value, 'name_hn')}  />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="Relation" name="relation_en" >
                                        <Select placeholder="Relation" onChange={handleRelationChange}>
                                            <Option value="Son of Mr.">Son of Mr.</Option>
                                            <Option value="Daughter of Mr.">Daughter of Mr.</Option>
                                            <Option value="Wife of Mr.">Wife of Mr.</Option>
                                            <Option value="Adopted Son of Mr.">Adopted Son of Mr.</Option>

                                        </Select>
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="Father/Husband Name" name="father_name_en" >
                                        <Input placeholder=" Father/Husband Name" onChange={(e) => handleInputChange(e.target.value, 'father_name_hn')}  />
                                    </Item>
                                </Col>



                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="Village" name="per_village_en" >
                                        <Input placeholder="Village" />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="Tehsil" name="per_tahsil_en" >
                                        <Input placeholder="Tehsil" />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="District" name="per_district_en" >
                                        <Input placeholder="District" />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="नाम" name="name_hn" >
                                        <Input placeholder="नाम दर्ज करें।"  />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="संबंध" name="relation_hn" >
                                        <Select placeholder="संबंध चुनें।">
                                            <Option value="पुत्र श्री">पुत्र श्री</Option>
                                            <Option value="सुपुत्री श्री">सुपुत्री श्री</Option>
                                            <Option value="पत्नी श्री">पत्नी श्री</Option>
                                            <Option value="गोद पुत्र श्री">गोद पुत्र श्री</Option>
                                        </Select>
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="पिता/पति का नाम" name="father_name_hn" >
                                        <Input placeholder="पिता/पति का नाम दर्ज करें।" />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="गाँव" name="per_village_hn" >
                                        <Input placeholder="गाँव" />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="तहसील" name="per_tahsil_hn" >
                                        <Input placeholder="तहसील" />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={4}>
                                    <Item className="custom-form-item" label="जिला" name="per_district_hn" >
                                        <Input placeholder="जिला" />
                                    </Item>
                                </Col>

                             

                                <Col xs={8} sm={8} lg={6} style={{marginTop:"15px"}}>
                                    <Space>
                                        <Button onClick={UserPaymentHistoryHandel} type="primary">User Payment History</Button>
                                        <Button onClick={AddUserDetails} type="success" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', color: '#fff' }}>Add/Edit/View Details</Button>
                                    </Space>
                                </Col>

                               
                         




                                <Col xs={24}><Title level={5}>Add New Payment Detail</Title> </Col>



                                <Col xs={16} sm={16} lg={16}>


                                    <Row gutter={[24, 0]}>

                                        <Col xs={24} sm={12} lg={12}>
                                            <Item className="custom-form-item" label="Account" name="bank_account"
                                                rules={[{ required: true, message: 'Select Bank Account' }]}
                                            >
                                                <Select onChange={handlePaymentTypeChange} className="bank_account" placeholder="Select Account" defaultValue="1" >
                                                    <Option value="1">ABRSVS(SHIKSHA NIDHI)-9078(SBI)</Option>
                                                    <Option value="2">ABRSVS(M_GAUSHALA)-4937(SBI)</Option>
                                                    <Option value="3">ABRSVS(ENVIRONMENT)-9598(AXIX)</Option>
                                                    <Option value="4">SKSS_BADLI(80G)</Option>
                                                    <Option value="5">SSS(80G)</Option>
                                                    <Option value="6">ANY OTHER</Option>
                                                </Select>
                                            </Item>

                                            <Item className="custom-form-item" name="is_special_occasion" valuePropName="checked">
                                                <Checkbox
                                                    checked={showSpecialOccassionCategory}
                                                    onChange={handleSpecialOccassionCategory}
                                                    className="custom-checkbox occansanscheck"
                                                > Is Special Occasion/Suspense Entries
                                                </Checkbox>
                                            </Item>

                                        </Col>

                                        {showSpecialOccassionCategory && (
                                            <>
                                                <Col xs={24} sm={12} lg={12}>
                                                    <Item className="custom-form-item" label="Special Occassion/Suspense Entries" name="special_occassion_category"
                                                        rules={[{ required: true, message: 'Select Special Occassion' }]}>
                                                        <Select placeholder="Special Occassion">
                                                            <Option value="Suspense-Entries">Suspense-Entries</Option>
                                                            <Option value="Birthday">Birthday</Option>
                                                            <Option value="Anngegment">Anngegment</Option>
                                                            <Option value="Marriage">Marriage</Option>
                                                            <Option value="Anniversary">Anniversary</Option>
                                                            <Option value="Bahan-Beti-Sammelan">Bahan-Beti-Sammelan</Option>

                                                            <Option value="Any-Other">Any Other</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>
                                            </>
                                        )}


                                        <Divider />
                                        <Col xs={24} sm={12} lg={8}>
                                            <Item className="custom-form-item" label="Amount Rs." name="amount" rules={[{ required: true, message: 'Please enter amount' }]}>
                                                <InputNumber className="input_number_type" placeholder="Enter Amount Rs." />
                                            </Item>
                                        </Col>
                                        <Col xs={24} sm={12} lg={8}>
                                            <Item className="custom-form-item"
                                                label="Tnx Detail (UTR/ID)"
                                                name="utr_number"
                                                rules={[
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && value.trim() === '') {
                                                                return Promise.reject(new Error('Input cannot be empty or contain only spaces'));
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                ]}
                                            >
                                                <AutoComplete
                                                    placeholder="Enter UTR/Tnx"
                                                    options={optionSuggestion}
                                                    onChange={(value) => {
                                                        // Remove all spaces from the input and call your handleChange function
                                                        const sanitizedValue = value.replace(/\s+/g, '');
                                                        handleChangeSuggestion(sanitizedValue, "utr_number");
                                                    }}
                                                    onSearch={(text) => getPanelValue(text.replace(/\s+/g, ''), "utr_number", "sn_payments")}
                                                />
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={8}>
                                            <Item className="custom-form-item" label="Tnx Date" >
                                                <DatePicker
                                                    onChange={handleDateChange}
                                                    value={transactionDate && moment(transactionDate)}
                                                    className="filter_dob" format="DD-MM-YYYY"
                                                    placeholder="Tnx Date" prefix={<CalendarOutlined />} />
                                            </Item>
                                        </Col>



                                        {showCategoryPay && showMonthCoverd && (
                                            <>
                                                <Divider orientation="left" plain><span style={{ fontWeight: 'bold' }}>  SHIKSHA NIDHI</span> </Divider>


                                                <Col xs={24} sm={12} lg={12}>
                                                    <Form.Item className="custom-form-item"
                                                        label="Month Covered"
                                                        name="month_range"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Select the month range',
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    if (!value || value.length !== 2) {
                                                                        return Promise.reject(new Error('Please select both start and end months'));
                                                                    }
                                                                    const [start, end] = value;
                                                                    if (start.isBefore(end) || start.isSame(end)) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(new Error('End month should be greater than or equal to start month'));
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <RangePicker
                                                            picker="month"
                                                            format="MM-YYYY"
                                                            placeholder={['Start Month', 'End Month']}
                                                            style={{ width: '100%' }}
                                                            monthCellRender={monthCellRender} // Customize month cell rendering
                                                            disabledDate={disabledDate} // Disable already paid months
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={12}>
                                                    <Item className="custom-form-item" label="Monthly Share(Rs.)" name="month_share_amount" rules={[{ required: true, message: 'Please Enter Monthly Share Rs.' }]}>
                                                        <InputNumber className="input_number_type" placeholder="Monthly Share(Rs.)" />
                                                    </Item>
                                                </Col>
                                            </>
                                        )}
                                        <Divider />

                                        <Col xs={24} sm={12} lg={8}>
                                            <Item className="custom-form-item" label="Mode of Payment" name="pyament_mode_type" >
                                                <Select onChange={handlePaymentModeChange} placeholder="Mode of Payment" >
                                                    <Option value="UPI">UPI</Option>
                                                    <Option value="Cash">Cash</Option>
                                                    <Option value="Cheque">Cheque</Option>
                                                    <Option value="RTGS-NEFT">RTGS/NEFT</Option>
                                                    <Option value="Website-Rozapay">Website-Rozapay</Option>
                                                    <Option value="Application-Rozapay">Application-Rozapay</Option>

                                                </Select>
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={8}>
                                            <Item className="custom-form-item" label="Payment Status" name="payment_virify" rules={[{ required: true, message: 'Please select verified status' }]} >
                                                <Select defaultValue="pending" value={paymentStatus} onChange={handlePaymentStatusChange} placeholder="Verified Status">
                                                    <Option value="pending">Pending</Option>
                                                    <Option value="verified">Verified</Option>
                                                    <Option value="failed">Failed</Option>

                                                </Select>
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={8}>
                                            <Item className="custom-form-item" label="Remark" name="remarks">
                                                <Input placeholder="Remark" />
                                            </Item>
                                        </Col>
                                        {showEReceiptFields && (
                                            <>
                                                <Divider />

                                                <Col xs={24} sm={12} lg={48}>
                                                    <Item className="custom-form-item" label=" C/R-Reciept" name="e_reciept_no">
                                                        <Input placeholder=" C/R - Reciept" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={8}>
                                                    <Item className="custom-form-item" label="C/R-Date">

                                                        <DatePicker onChange={handleReciptDateChange}
                                                            value={ereciept_date && moment(ereciept_date)}
                                                            className="filter_dob" format="DD-MM-YYYY"
                                                            placeholder="C/R-Date"
                                                            prefix={<CalendarOutlined />} />



                                                    </Item>
                                                </Col>
                                            </>
                                        )}
                                    </Row>

                                </Col>
                                <Col xs={8} sm={8} lg={8}>
                                    <Col xs={24} sm={24} lg={24}>

                                        {optionSuggestion && optionSuggestion.length > 0 && (
                                            <Card style={{ backgroundColor: "rgb(255 204 204)", marginBottom: "10px" }}>
                                                <span style={{ fontSize: '16px', fontWeight: "bold", color: "rgb(255 64 64)", margin: "10px 0px 0px 19px", display: "flex", borderBottom: "1px solid #efeaea" }}
                                                >Alert: Already Exists</span>

                                                <div style={{ marginBottom: '10px', marginTop: '10px', paddingLeft: '20px' }}>
                                                    <span style={{ marginRight: '15px' }}> <strong>Txn Detail: </strong> {optionSuggestion[0].value}  </span>
                                                </div>

                                                <div style={{ marginBottom: '10px', marginTop: '10px', paddingLeft: '20px' }}>
                                                    <span style={{ marginRight: '15px' }}> <strong>Name: </strong>{optionSuggestion[0].name} </span>
                                                </div>
                                                <div style={{ marginBottom: '10px', marginTop: '10px', paddingLeft: '20px' }}>
                                                    <span style={{ marginRight: '15px' }}> <strong>Mo.No: </strong>
                                                        {optionSuggestion[0].phone_no}
                                                    </span>
                                                </div>
                                                <div style={{ marginBottom: '10px', marginTop: '10px', paddingLeft: '20px' }}>
                                                    <span style={{ marginRight: '15px' }}><strong>Txn Date: </strong>
                                                        {optionSuggestion[0].transaction_date ? moment(optionSuggestion[0].transaction_date).format('DD MMMM YYYY') : '-'}
                                                    </span>
                                                </div>

                                            </Card>

                                        )}

                                    <Card style={{ backgroundColor: "rgb(219 209 239)", padding: "16px",marginBottom :"10px" }}>
                                            <Row gutter={[16, 16]}>
                                            <Col xs={24} sm={12} lg={6}>
                                                <Item label="Calendar?" name="is_calendar">
                                                <Select placeholder="Select Calendar">
                                                    <Option value="Yes">Yes</Option>
                                                    <Option value="No">No</Option>
                                                </Select>
                                                </Item>
                                            </Col>

                                            <Col xs={24} sm={12} lg={6}>
                                                <Item label="Count" name="no_calendar">
                                                <Input placeholder="Enter No. Calendar" />
                                                </Item>
                                            </Col>

                                            <Col xs={24} sm={12} lg={6}>
                                                <Item label="Name" name="calendar_per_name">
                                                <Input placeholder="Person/Village" />
                                                </Item>
                                            </Col>

                                            <Col xs={24} sm={12} lg={6}>
                                                <Item label="Honored?" name="is_honored">
                                                <Select placeholder="Select Honored">
                                                    <Option value="Yes">Yes</Option>
                                                    <Option value="No">No</Option>
                                                </Select>
                                                </Item>
                                            </Col>
                                            </Row>
                                        </Card>


                                        {lastPayment && (
                                            <Card style={{ backgroundColor: "rgb(209 223 239)" }}>
                                                <span
                                                    style={{ fontSize: '16px', fontWeight: "bold", margin: "10px 0px 0px 19px", display: "flex", borderBottom: "1px solid #efeaea" }}
                                                >Last Payment History</span>

                                                <div style={{ marginBottom: '10px', marginTop: '10px', paddingLeft: '20px' }}>
                                                    <span style={{ marginRight: '15px' }}> <strong>Amount: </strong>
                                                        Rs.{lastPayment?.[0]?.last_payment_amount || ''}
                                                    </span>
                                                </div>
                                                <div style={{ marginBottom: '10px', marginTop: '10px', paddingLeft: '20px' }}>
                                                    <span style={{ marginRight: '15px' }}> <strong>Txn Date: </strong>
                                                        {lastPayment[0].transaction_date ? moment(lastPayment[0].transaction_date).format('DD MMMM YYYY') : '-'}
                                                    </span>
                                                </div>
                                                <div style={{ marginBottom: '10px', marginTop: '10px', paddingLeft: '20px' }}>
                                                    <span style={{ marginRight: '15px' }}><strong>MOD: </strong>
                                                        {lastPayment?.[0]?.pyament_mode_type || ''}
                                                    </span>
                                                </div>

                                                <div style={{ marginBottom: '10px', paddingLeft: '20px', borderBottom: "1px solid #efeaea" }}>
                                                    <span style={{ marginRight: '10px' }}> <strong>Txn Detail: </strong>
                                                        {lastPayment?.[0]?.utr_number || ''}
                                                    </span>

                                                </div>
                                                <div style={{ marginBottom: '10px', paddingLeft: '20px', borderBottom: "1px solid #efeaea" }}>
                                                    <span style={{ marginRight: '15px' }}><strong>UM/Y (Advance): </strong>

                                                        {lastPayment[0].end_month ? moment(lastPayment[0].end_month).format('MMMM YYYY') : '-'}
                                                    </span>
                                                </div>
                                                <div style={{ marginBottom: '10px', paddingLeft: '20px', borderBottom: "1px solid #efeaea" }}>
                                                    <span style={{ marginRight: '15px' }}><strong>Remarks: </strong>
                                                        {lastPayment?.[0]?.remarks || ''}
                                                    </span>
                                                </div>



                                                <div style={{ paddingLeft: '20px', borderBottom: "1px solid #efeaea",color:"#fff", display: "flex", backgroundColor: "rgb(18 207 237)", padding: "8px" }}>
                                                    <span style={{ marginRight: '10px' }}><strong>Join Date: </strong>
                                                        {lastPayment[0].joining_date ? moment(lastPayment[0].joining_date).format('DD MMMM YYYY') : '-'}
                                                    </span>
                                                    <span style={{ marginRight: '10px' }}><strong>TAR: </strong>Rs.{lastPayment?.[0]?.total_amount || ''}   </span>
                                                    <span style={{ marginRight: '10px' }}><strong>T Duration: </strong>{lastPayment?.[0]?.total_duration || ''} </span>
                                                </div>


                                            </Card>
                                        )}
                                    </Col>

                                </Col>


                            </Row>
                            <Row >
                                <Col xs={24} sm={12} lg={3} style={{ marginRight: '16px' }}>
                                    <Item>
                                        <Button type="primary" htmlType="submit"
                                            onClick={() => setAction('save')}
                                        >
                                            Save/View Payment
                                        </Button>
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={3} style={{ marginRight: '16px' }}> 
                                    <Item>
                                        <Button
                                            onClick={() => setAction('next')}
                                            style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', color: '#fff' }} htmlType="submit">
                                            Save/Next Payment
                                        </Button>
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={3}>
                                    <Item>
                                        <Button
                                            onClick={() => setAction('not_send')}
                                            style={{ backgroundColor: 'rgb(196 26 26)', borderColor: 'rgb(196 26 26)', color: '#fff' }} htmlType="submit">
                                            Save/No Photo/After Send Msg
                                        </Button>
                                    </Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <Modal width={1000}
                title="Payment Information"
                open={isModalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleModalClose}>
                        OK
                    </Button>,
                ]}
            >
                <div className="scrollable-modal-content">
                    {addedUserData && <UserModalContent userData={addedUserData} />}
                </div>

            </Modal>

            <Modal width={1000}
                // title="User Payment Details"
                open={UserPaymentHistory}
                onOk={handleUserPaymentHistoryClose}
                onCancel={handleUserPaymentHistoryClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleUserPaymentHistoryClose}>
                        OK
                    </Button>,
                ]}
                title={
                    <Row justify="space-between" align="middle" style={{ marginRight: 20 }}>
                        <Col>
                            User Payment Details
                        </Col>
                        <Col>
                            {userId && (
                                <span style={{ marginRight: 10 }}>Member Status </span>
                            )}
                            {userId && (
                                <Switch checked={switchChecked} onChange={handleSwitchChange} />
                            )}
                        </Col>
                    </Row>
                }
            >
                <div className="scrollable-modal-content">

                    {paymentUserData ? (
                        <UserPaymentModalHistory userid={paymentUserData} />
                    ) : (
                        <div>No Record Data</div>
                    )}

                </div>

            </Modal>

            <Drawer
                title="User Details"
                width={1000}
                onClose={AddUserDetailsClose}
                open={AddUserDetailsFormModel}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                maskClosable={false}
            >


                <AddUserDetailsModel userData={AddEditUserDetailsForm} userBasicDetail={userBasicDetail} primaryId={primaryId} onIdChange={handleIdChange} />

            </Drawer>

            {/* <Modal
                title="For confirmation"
                open={isModalForConfirmation}
                onOk={handleOk}
                onCancel={handleModalForConfirmationClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleOk}>
                        OK
                    </Button>,
                ]}
                maskClosable={false} // Prevent closing on clicking outside
            >
                <div className="scrollable-modal-content">
                    <h4>Primary Number : {alternativeNumber1 ? alternativeNumber1 : alternativeNumber2}</h4>

                    <Checkbox
                        value="usePrimaryNumber"
                        checked={checkedOption === 'usePrimaryNumber'}
                        onChange={handleCheckboxChange}
                    >
                        Use primary number
                    </Checkbox>
                    <br />
                    <Checkbox
                        value="sameAsAlternateNumber"
                        checked={checkedOption === 'sameAsAlternateNumber'}
                        onChange={handleCheckboxChange}
                    >
                        Same as alternate number
                    </Checkbox>
                    <br />
                    <Checkbox
                        value="newMember"
                        checked={checkedOption === 'newMember'}
                        onChange={handleCheckboxChange}
                    >
                        New member
                    </Checkbox>
                </div>
            </Modal> */}

            <Modal
                title="New Member"
                open={isModalAddMember}
                onOk={handleModalNewMemberClose}
                onCancel={handleModalNewMemberClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleModalNewMemberClose}>
                        OK
                    </Button>
                ]}
                maskClosable={false} // Prevent closing on clicking outside
            >
                <Row>
                    <Col span={24}>
                        {userId && (
                            <Button style={{ "float": "right" }} className="ant-btn-primary search-add-button" onClick={addNewMember}>New Member</Button>
                        )}
                    </Col>
                </Row>

                <div className="table-responsive">
                    <Table
                        columns={columnsChildren}
                        dataSource={childrenList.map((user, index) => ({
                            key: index,
                            id: user.id, // Ensure _id is included in the data source
                            name_eng: `${user.name_eng} ${user.name_hn}`
                        }))}
                        pagination={false}
                        className="ant-border-space"
                    />
                </div>
            </Modal>


             <Modal
                    open={isModalVisibleImg}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      src={currentImage}
                      alt="Profile"
                      style={{ width: '100%' }}
                    />
                  </Modal>

        </div>
        </>
    );
}

export default PaymentsForm;