// permissions.js

export function getPermissions() {
    const storedPermission = localStorage.getItem('permission');
    return storedPermission ? JSON.parse(storedPermission) : [];
}

// Check if a user has permission for a specific module & action
export function hasPermission(moduleName, action) {
    const permissions = getPermissions();
    
    // Find the permission object for the module
    const modulePermissions = permissions.find(perm => perm.url === moduleName);
    if (!modulePermissions) return false;

    // Check if the action is allowed (1 means allowed, 0 means denied)
    return modulePermissions[action] === 1;
}

// Protect routes by checking if the user has `readPermission`
export function canAccessRoute2(route) {
    console.log('url-',route)
    const permissions = getPermissions();
    return permissions.some(perm => perm.url === route && perm.readPermission === 1);
}
// Extract base module from URL
function getBaseModule(url) {
    const parts = url.split("/").filter(Boolean);
    if (parts.length > 1 && (parts[1] === "add" || parts[1] === "update" || parts[1] === "view")) {
        return `/${parts[0]}`; // Extract base module like `/role`, `/payments`
    }
    return url; // Default for list page
}




// Protect routes by checking if the user has appropriate permissions
 // Protect routes by checking if the user has appropriate permissions
export function canAccessRoute(route) {
    const permissions = getPermissions();
    const baseModule = getBaseModule(route);

    console.log("🔍 Checking Route:", route);
    console.log("🔗 Extracted Base Module:", baseModule);
    console.log("🛡️ User Permissions:", permissions);

    // Find module permissions
    const modulePermissions = permissions.find(perm => perm.url === baseModule);
    
    if (!modulePermissions) {
        console.log("❌ No permissions found for:", baseModule);
        return false;
    }

    console.log("✅ Found Permissions:", modulePermissions);

    // Allow access based on permission types
    if (route === baseModule) {
        console.log("📖 Checking Read Permission");
        return modulePermissions.readPermission === 1;
    }

    if (route.startsWith(`${baseModule}/add`)) {
        console.log("➕ Checking Create Permission");
        return modulePermissions.createPermission === 1;
    }

    if (route.startsWith(`${baseModule}/update/`)) {
        console.log("✏️ Checking Edit Permission");
        return modulePermissions.editPermission === 1;
    }

    // ✅ **New Condition: Check View Permission**
    if (route.startsWith(`${baseModule}/view/`)) {
        console.log("👀 Checking View Permission");
        return modulePermissions.readPermission === 1; // Use `readPermission` for view access
    }

    console.log("🚫 Access Denied for:", route);
    return false;
}




