import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../config";
import moment from "moment";

import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Tabs,
  Empty,
  notification,
  Select,
  DatePicker,
  Avatar,
  Modal,
  Space,Result 
} from "antd";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
  EyeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";

import ava1 from "../assets/images/logo-shopify.svg";
import ava2 from "../assets/images/logo-atlassian.svg";
import team1 from "../assets/images/team-1.jpg";
import team2 from "../assets/images/team-2.jpg";
import team3 from "../assets/images/team-3.jpg";
import team4 from "../assets/images/team-4.jpg";
import guruJiImg from "../assets/images/gurujimaharaj.jpeg";
import userDummy from "../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../components/permissions';


const { MonthPicker, RangePicker } = DatePicker;

const { TabPane } = Tabs;
const { Option } = Select;

function Home() {
  const { Title, Text } = Typography;

  const onChange = (e) => console.log(`radio checked:${e.target.value}`);
  const [reverse, setReverse] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedUserYear, setSelectedUserYear] = useState(
    new Date().getFullYear()
  );
  //For show image on model larges
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [userData, setUserData] = useState({
    total_users: 0,
    total_payments: 0,
    NewUserlist: [],
    NewPaymentlist: [],
    dobMonthList: [],
    memberGraph: [],
    paymentGraph: [],
  });

  const [filters, setFilters] = useState({
    entry_date_range: [],
  });

  useEffect(() => {
    fetchDashboardData(
      selectedYear,
      selectedUserYear,
      filters.entry_date_range
    );
  }, [selectedYear, selectedUserYear, filters.entry_date_range]);

  async function fetchDashboardData(year, selectedUserYear, dateRange) {
    try {
      const response = await axios.get(`${baseUrl}/dashboard/dataList`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        params: {
          year: year,
          userYear: selectedUserYear,
          startDate: dateRange?.[0] || null,
          endDate: dateRange?.[1] || null,
        },
      });
      if (response.data.status) {
        setUserData(response.data.result);
      } else {
        notification.info({
          message: "Info",
          description: response.data.message,
          placement: "topRight",
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: "Info",
        description: error.response?.data?.message,
        placement: "topRight",
      });
    }
  }

  const handleYearChange = (date, dateString) => {
    setSelectedYear(date ? date?.year() : new Date().getFullYear());
  };

  const handleUserYearChange = (date, dateString) => {
    setSelectedUserYear(date ? date?.year() : new Date().getFullYear());
  };

  // Remove API call from handleFilterChange
  // const handleFilterChange = (field, value) => {
  //   const newFilters = {
  //     ...filters,
  //     [field]: value
  //   };
  //   setFilters(newFilters);
  // };

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const inr = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zm1-13h-4v2h4v1h-4v2h4c0 1.657-1.343 3-3 3v2c2.761 0 5-2.239 5-5h1v-2h-1V7zm0-2V3h-2v2h2z"
        fill="#fff"
      />
    </svg>,
  ];

  const profile = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
        fill="#fff"
      ></path>
      <path
        d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
        fill="#fff"
      ></path>
      <path
        d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
        fill="#fff"
      ></path>
      <path
        d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
        fill="#fff"
      ></path>
    </svg>,
  ];

  const count = [
    {
      today: "Total Donation",
      title: "Rs." + userData.total_payments,
      icon: "₹",
      bnb: "bnb2",
    },
    {
      today: "Total Members",
      title: userData.total_users,
      icon: profile,
      bnb: "bnb2",
    },
    {
      today: "Today’s Samarpan",
      title: "Rs." + userData.today_payments,
      icon: "₹",
      bnb: "redtext",
    },
    {
      today: "Today’s Member",
      title: userData.today_users,
      icon: profile,
      bnb: "bnb2",
    },
  ];

  const NewPendinglist = [];

  const Highestlist = [];

  const showModal = (image) => {
    setCurrentImage(image || userDummy);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      

      {hasPermission('/dashboard', 'readPermission') ? (
        <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div>
                  <Title level={5}>Payment History</Title>
                  <Paragraph className="lastweek"></Paragraph>
                </div>
                <div className="ant-filtertabs">
                  <div className="amount-range-input">
                    <RangePicker
                      className="filter_dob"
                      format="DD-MM-YYYY"
                      value={
                        Array.isArray(filters.entry_date_range) &&
                        filters.entry_date_range.length === 2
                          ? [
                              moment(filters.entry_date_range[0], "YYYY-MM-DD"),
                              moment(filters.entry_date_range[1], "YYYY-MM-DD"),
                            ]
                          : []
                      }
                      onChange={(dates, dateStrings) => {
                        const formattedRange = dates
                          ? dates.map((date) => date.format("YYYY-MM-DD"))
                          : [];
                        setFilters((prev) => ({
                          ...prev,
                          entry_date_range: formattedRange,
                        }));
                        fetchDashboardData(
                          selectedYear,
                          selectedUserYear,
                          formattedRange
                        ); // Send range to API
                      }}
                      placeholder={["From Date", "To Date"]}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>

              {/* Padding for Proper Spacing */}

              <div>
                <Row className="rowgap-vbox" gutter={[24, 16]}>
                  {count.map((c, index) => (
                    <Col
                      key={index}
                      xs={24}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="mb-24"
                    >
                      <Card
                        bordered={false}
                        className="criclebox"
                        style={{ padding: "16px" }}
                      >
                        <div className="number">
                          <Row align="middle" gutter={[16, 0]}>
                            <Col xs={18}>
                              <span>{c.today}</span>
                              <Title level={3}>
                                {c.title}{" "}
                                <small className={c.bnb}>{c.persent}</small>
                              </Title>
                            </Col>
                            <Col xs={6}>
                              <div className="icon-box">{c.icon}</div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
        <hr
          style={{
            backgroundColor: "#ccc", // light yellow background color
            color: "#ccc",
          }}
        ></hr>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div>
                  <Title level={5}>Payment History </Title>
                  <Paragraph className="lastweek"></Paragraph>
                </div>
                <div className="ant-filtertabs">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Radio.Group
                      onChange={onChange}
                      defaultValue="a"
                    ></Radio.Group>
                  </div>
                </div>
              </div>

              <Tabs defaultActiveKey="1" type="card" size="default">
                <TabPane tab="Recent Entries" key="1">
                  <div className="ant-list-box table-responsive">
                    <table className="width-100">
                      <thead>
                        <tr>
                          <th>
                            <Text strong>SR.no</Text>
                          </th>
                          <th>
                            <Text strong>Amount Rs.</Text>
                          </th>
                          <th>
                            <Text strong>Member</Text>
                          </th>
                          <th>
                            <Text strong>Tnx (UTR/ID)</Text>
                          </th>
                          <th>
                            <Text strong>Tnx Date</Text>
                          </th>
                          <th>
                            <Text strong>Entry Date</Text>
                          </th>
                          <th>
                            <Text strong>Payment Verify</Text>
                          </th>
                          <th>
                            <Text strong>View</Text>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData.NewPaymentlist.length > 0 ? (
                          userData.NewPaymentlist.map((d, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {" "}
                                <h6>Rs.{d.amount} </h6>{" "}
                              </td>
                              <td>
                                {d.name_eng}
                                <br />
                                {d.phone_no}
                              </td>
                              <td>{d.utr_number}</td>
                              <td>
                                {" "}
                                {d.transaction_date
                                  ? moment(d.transaction_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td>
                                {" "}
                                {d.payment_entry_date
                                  ? moment(d.payment_entry_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td>{d.payment_virify}</td>
                              <td>
                                {" "}
                                <Link
                                  to={`/payments/view/${d.payment_id}`}
                                  className="update-btn"
                                >
                                  <EyeOutlined />
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <Empty description="No Data" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </TabPane>

                <TabPane tab="New Members" key="2">
                  <div className="ant-list-box table-responsive">
                    <table className="width-100">
                      <thead>
                        <tr>
                          <th>
                            <Text strong>SR.no</Text>
                          </th>
                          <th>
                            <Text strong>Member Name</Text>
                          </th>
                          <th>
                            <Text strong>Phone no</Text>
                          </th>
                          <th>
                            <Text strong>Father Name</Text>
                          </th>
                          <th>
                            <Text strong>joining Date</Text>
                          </th>
                          <th>
                            <Text strong>View</Text>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData.NewUserlist.length > 0 ? (
                          userData.NewUserlist.map((d, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {" "}
                                <h6> {d.name_eng} </h6>{" "}
                              </td>
                              <td>{d.phone_no}</td>
                              <td>{d.father_name_en}</td>
                              <td>
                                {d.joining_date
                                  ? moment(d.joining_date).format("DD-MM-YYYY")
                                  : "-"}
                              </td>
                              <td>
                                {" "}
                                <Link
                                  to={`/users/view/${d.id}`}
                                  className="update-btn"
                                >
                                  <EyeOutlined />
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <Empty description="No Data" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </TabPane>

                <TabPane tab="Highest Payment" key="4">
                  <div className="ant-list-box table-responsive">
                    <table className="width-100">
                      <thead>
                        <tr>
                          <th>
                            <Text strong>SR.no</Text>
                          </th>
                          <th>
                            <Text strong>Member</Text>
                          </th>
                          <th>
                            <Text strong>Amount Rs.</Text>
                          </th>
                          <th>
                            <Text strong>View</Text>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Highestlist.length > 0 ? (
                          Highestlist.map((d, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {" "}
                                <h6> {d.amount} </h6>{" "}
                              </td>
                              <td>
                                {d.user_name}
                                <br />
                                {d.phone_no}
                              </td>
                              <td>
                                {" "}
                                <Link
                                  to={`/payments/view/id`}
                                  className="update-btn"
                                >
                                  <EyeOutlined />
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <Empty description="No Data" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="timeline-box">
                <Title level={5}>Birthday’s Reminder </Title>
                <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                  today/upcoming
                </Paragraph>
                <Timeline className="timelinelist">
                  {userData.dobMonthList.length > 0 ? (
                    userData.dobMonthList.map((t, index) => (
                      <Timeline.Item color="green" key={index}>
                        <Space align="center" size="small">
                          <Avatar
                            className="shape-avatar"
                            shape="square"
                            size={20}
                            onClick={() =>
                              showModal(
                                t.profile_img ? t.profile_img : userDummy
                              )
                            }
                            src={t.profile_img ? t.profile_img : userDummy}
                            icon={!t.profile_img && <UserOutlined />}
                            style={{ cursor: "pointer" }}
                          />
                          <Title level={5} style={{ margin: 0 }}>
                            {t.name}
                            {t.announced_amount
                              ? " (Rs." + t.announced_amount + ")"
                              : ""}
                          </Title>
                          <Text
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#1890ff", // light yellow background color
                              padding: "2px 4px", // optional padding for better visual appearance
                              borderRadius: "4px", // optional rounded corners
                              color: "#fff",
                            }}
                          >
                            {t.dob ? moment(t.dob).format("DD-MMMM") : "-"}
                          </Text>
                        </Space>
                      </Timeline.Item>
                    ))
                  ) : (
                    <Empty description="No Data" />
                  )}
                </Timeline>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Link to="/reports/birthday-list">
                    <Button type="primary" className="width-100">
                      View All
                    </Button>
                  </Link>
                </div>{" "}
              </div>
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="linechart" style={{ marginBottom: 50 }}>
                <div>
                  <Title level={5}>Active Users</Title>
                  <Paragraph className="lastweek"></Paragraph>
                </div>
                <div className="sales">
                  {/* <Select
                    defaultValue={selectedUserYear}
                    style={{ width: 120 }}
                    onChange={handleUserYearChange}
                  >
                    <Option value={2024}>2024</Option>
                    <Option value={2023}>2023</Option>
                    <Option value={2022}>2022</Option>
                    <Option value={2021}>2021</Option>
                  </Select> */}
                  <DatePicker
                    picker="year"
                    defaultValue={moment(selectedUserYear, "YYYY")}
                    onChange={handleUserYearChange}
                    style={{ width: 120 }}
                  />
                </div>
              </div>
              <Echart data={userData.memberGraph} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="linechart">
                <div>
                  <Title level={5}>Payment History</Title>
                  <Paragraph className="lastweek"></Paragraph>
                </div>
                <div className="sales">
                  {/* <Select
                    defaultValue={selectedYear}
                    style={{ width: 120 }}
                    onChange={handleYearChange}
                  >
                    <Option value={2024}>2024</Option>
                    <Option value={2023}>2023</Option>
                    <Option value={2022}>2022</Option>
                    <Option value={2021}>2021</Option>
                  </Select> */}
                  <DatePicker
                    picker="year"
                    defaultValue={moment(selectedYear, "YYYY")}
                    onChange={handleYearChange}
                    style={{ width: 120 }}
                  />
                </div>
              </div>
              <LineChart data={userData.paymentGraph} />
            </Card>
          </Col>
        </Row>
        </div>
) : (
  <div className="tabled">
    <Result
      status="403"
      title="No Access"
      subTitle="You do not have permission to view this section."
    />
  </div>
)}

    
      <Modal open={isModalVisible} footer={null} onCancel={handleCancel}>
        <img src={currentImage} alt="Profile" style={{ width: "100%" }} />
      </Modal>
    </>
  );
}

export default Home;
