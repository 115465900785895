import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../config";
import moment from "moment";

import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Tabs,
  Empty,
  notification,
  Select,
  DatePicker,
  Avatar,
  Modal,
  Space,Result 
} from "antd";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
  EyeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";

import ava1 from "../assets/images/logo-shopify.svg";
import ava2 from "../assets/images/logo-atlassian.svg";
import team1 from "../assets/images/team-1.jpg";
import team2 from "../assets/images/team-2.jpg";
import team3 from "../assets/images/team-3.jpg";
import team4 from "../assets/images/team-4.jpg";
import guruJiImg from "../assets/images/gurujimaharaj.jpeg";
import userDummy from "../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../components/permissions';


const { MonthPicker, RangePicker } = DatePicker;

const { TabPane } = Tabs;
const { Option } = Select;

function NoAccess() {
  const { Title, Text } = Typography;
  

  return (
    <>
       
  <div className="tabled">
    <Result
      status="403"
      title="No Access"
      subTitle="You do not have permission to view this section."
    />
  </div>
 

    
     
    </>
  );
}

export default NoAccess;
