import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import * as XLSX from 'xlsx';
import {
  Row, Col, Card, Table, Button, Input, AutoComplete,
  notification, Modal, Select, Avatar, Typography, TableColumnsType,
  Checkbox, DatePicker, Drawer, Form, InputNumber, Space, Tooltip, Upload
} from "antd";


import axios from "axios";
import { baseUrl } from "../../config"; 
import {
  DeleteOutlined, FileExcelOutlined, CalendarOutlined, SendOutlined,
  FilterOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined,
  EyeOutlined, UserOutlined, PlusOutlined, UploadOutlined
} from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';

import AddUserDetailsModel from './Form';
 
import InquiryForm from './Form';      

const { confirm } = Modal;
const { Search } = Input; 
const { Option } = Select;
const { Title } = Typography;
const { Item } = Form;
const { TextArea } = Input;
   

// Table columns definition
const columns = [

  {
    title: "#",
    dataIndex: "key",
    key: "key",
    fixed: 'left',
    width: 50,
  },
  {
    title: "Assigned By",
    dataIndex: "assigned_by",
    key: "assigned_by",
    fixed: 'left',
    width: 150,
  },

  {
    title: "Assigned Date",
    dataIndex: "assigned_date",
    key: "assigned_date",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: 'Assigned To',
    dataIndex: 'assigned_to_name',
    key: 'assigned_to_name',
  },

  {
    title: "Remark By Assignee",
    dataIndex: "remark_by_assignee",
    key: "remark_by_assignee"
  },
  {
    title: "Date Completion",
    dataIndex: "date_completion",
    key: "date_completion",
  },
  {
    title: "Remark By Staff",
    dataIndex: "remark_by_staff",
    key: "remark_by_staff",
  },
  
  {
    title: "CreatedAt",
    dataIndex: "createdAt",
    key: "createdAt",
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    fixed: 'right',
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    fixed: 'right',
  }
];



function Inquiry() {

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPageQuery = parseInt(queryParams.get('page')) || 1;
  const limitQuery = parseInt(queryParams.get('limit')) || 20;

  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(limitQuery);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [purnimaSettingRecord, setPurnimaSettingRecord] = useState();
  const [filters, setFilters] = useState({
    assigned_by: queryParams.get('assigned_by') || '',
    assigned_date: queryParams.get('assigned_date') || '',
    description: queryParams.get('description') || '',
    inquiry_regards: queryParams.get('inquiry_regards') || '',
    description: queryParams.get('description') || '',
    assigned_to: queryParams.get('assigned_to') || '',
    remark_by_assignee: queryParams.get('remark_by_assignee') || '',
    date_completion: queryParams.get('date_completion') || '', 
    remark_by_staff: queryParams.get('remark_by_staff') || '', 
    status: queryParams.get('status') || '',
    createdAt: queryParams.get('createdAt') || '',
  });
 
  const [showUserFilter, setShowUserFilter] = useState(true);
  const [showPurnimaMessageForm, setShowPurnimaMessageForm] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleImportList, setIsModalVisibleImportList] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const { id } = useParams(); // Extract id from URL 
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [txnDate, setTxnDate] = useState();
  const [meetingDate, setMeetingDate] = useState();
  const [addForm, setAddForm] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);
  const [userId, setuserId] = useState();


  useEffect(() => {
    getStaffList();
    const { state } = history.location;  
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }, [history.location]);


  const AddUserDetailsClose = () => {
    setAddUserDetailsModel(false)
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }



  async function getUserList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/work_managment/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        // setUserList([])
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  async function handleDelete(id) {
    try {
      const response = await axios.delete(`${baseUrl}/work_managment/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (response.status === 200) {
        getUserList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
        notification.success({
          message: 'Success',
          description: 'Record deleted successfully!',
          placement: 'topRight'
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }    
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);

    // Update both current page and limit in the history
    history.push(`?page=${current}&limit=${pageSize}`);

    // Fetch the updated payment list
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };


  const handleLimitChange = value => {
    setLimit(value);

    // Update URL with the new limit and reset page to 1
    const updatedQueryParams = new URLSearchParams(location.search);
    updatedQueryParams.set('limit', value);
    updatedQueryParams.set('page', 1); // Reset to first page whenever limit changes

    // Push new URL with updated query params
    history.push(`?${updatedQueryParams.toString()}`);

    // Call the API to fetch data with updated limit
    getUserList(1, value);  // Assuming your API call accepts page and limit
  };


  const handleFilterChange = (field, value) => {
    const newFilters = {
      ...filters,
      [field]: value
    };
    setFilters(newFilters);

    // Optionally, update the URL without triggering a search immediately
    const updatedQueryParams = new URLSearchParams(location.search);
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key]) {
        updatedQueryParams.set(key, newFilters[key]);
      } else {
        updatedQueryParams.delete(key); // Remove empty filters from the URL
      }
    });
    history.push(`?${updatedQueryParams.toString()}`);
  };


  const handleUserFilterCheckboxChange = (e) => {
    setShowUserFilter(e.target.checked);

  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelImportList = () => {
    setIsModalVisibleImportList(false);
  };

  const handleOnSuccess = () => {
    setIsModalVisibleImportList(false);
    getUserList();
  };



  const toggleFormVisibility = () => {
    setAddForm(prevState => !prevState); // Toggle between true and false
  };


  const AddUserDetails = async (userId) => {
    console.log('uid', userId)
    setuserId(userId);
    setAddUserDetailsModel(true)

  }

  async function getStaffList() {
    try {
      const response = await axios.get(`${baseUrl}/staff/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setStaffList(response.data.result);
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRighft'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const onRefresh = async () => {
    setAddForm(false);
    getUserList();
  }

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>

        {addForm && (
          <Col xs="24" xl={24}>
            <InquiryForm userId={null} onRefreshData={onRefresh} />
          </Col>

        )}
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Work Managment List"
            extra={
              <div style={{ display: 'flex', gap: '10px' }}>
                {hasPermission('/work_managment', 'createPermission') && (
                  <button onClick={toggleFormVisibility} className="custom-btn">Add Work Managment Form</button>
                )}
              </div>
            }

          >
            <div className="table-responsive">
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <FilterOutlined /> <Checkbox onChange={handleUserFilterCheckboxChange}><Title style={{ fontSize: 13 }} level={5}>Form Filter</Title></Checkbox>
                      </Col>
                      {showUserFilter && (
                        <>
                          <Col span={4}> <Input placeholder="Assigned By" value={filters.assigned_by} onChange={e => handleFilterChange('assigned_by', e.target.value)} /></Col>
                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.assigned_date ? moment(filters.assigned_date, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('assigned_date', dateString)} placeholder="Assigned Date"
                            />
                          </Col>

                          <Col span={4}><Input placeholder="Description" value={filters.description} onChange={e => handleFilterChange('description', e.target.value)} /></Col>

                          <Col span={4}>
                              <Select style={{ width: '100%' }} placeholder="Assigned To" value={filters.assigned_to} onChange={value => handleFilterChange('assigned_to', value)}>

                                <Option value="">Assigned To</Option>
                                {staffList && staffList.map((item, i) => (
                                  <Option value={item.id}>{item.firstName} {item.lastName}</Option>
                                ))}
                              </Select> 
                              </Col>

                          <Col span={4}> <Input placeholder="Remark By Assignee" value={filters.remark_by_assignee} onChange={e => handleFilterChange('remark_by_assignee', e.target.value)} /></Col>
                         

                          <Col span={4}> 
                            <Select style={{ width: '100%' }} placeholder="Assigned By" value={filters.attend_by} onChange={value => handleFilterChange('attend_by', value)}>

                              <Option value=""> Assigned By</Option>
                              {staffList && staffList.map((item, i) => (
                                <Option value={item.id}>{item.firstName} {item.lastName}</Option>
                              ))}
                            </Select>
                            </Col>

                            <Col span={4}>
                              <DatePicker className="filter_dob CustomInputSet"
                                format="DD-MM-YYYY" value={filters.date_completion ? moment(filters.date_completion, 'DD-MM-YYYY') : null}
                                onChange={(date, dateString) => handleFilterChange('date_completion', dateString)} placeholder="Date Completion"
                              />
                            </Col>


                          

                             <Col span={4}>
                              <Input placeholder="Remark By Staff" value={filters.remark_by_staff} onChange={e => handleFilterChange('remark_by_staff', e.target.value)}
                              />
                            </Col>

                           


                            <Col span={4}>

                            <Select style={{ width: '100%' }} placeholder="Status" value={filters.status} onChange={value => handleFilterChange('status', value)}>
                              <Option value="">Select Status</Option>
                              <Option value="Pending">Pending</Option>
                              <Option value="InProcess">InProcess</Option>
                              <Option value="Completed">Completed</Option>
                             
                            </Select>

                             
                            </Col>

                            <Col span={4}>
                              <DatePicker className="filter_dob CustomInputSet"
                                format="DD-MM-YYYY" value={filters.createdAt ? moment(filters.createdAt, 'DD-MM-YYYY') : null}
                                onChange={(date, dateString) => handleFilterChange('createdAt', dateString)} placeholder="Created Date"
                              />
                            </Col>


                          </>
                      )}

                          <Col xs={24}>
                            <span style={{ marginRight: 10 }}>Records per page:</span>
                            <Select defaultValue={20} onChange={handleLimitChange}>
                              <Option value={20}>20</Option>
                              <Option value={40}>40</Option>
                              <Option value={60}>60</Option>
                              <Option value={80}>80</Option>
                              <Option value={100}>100</Option>
                            </Select>
                          </Col>
                        </Row>
                  </div>
                )}
                dataSource={userList.map((user, index) => {
                   return {
                    key: (limit * (currentPage - 1)) + index + 1,
                    assigned_by: (
                      <div className="author-info">
                        <Title level={5} >
                          {user.assigned_by}
                        </Title>

                      </div>
                    ),
                    assigned_date: (
                      <>
                        <div className="author-info">
                        <Title level={5}>{user.assigned_date && user.assigned_date != "0000-00-00" ? moment(user.assigned_date).format('DD MMMM YYYY') : '-'}</Title>
                        </div>
                      </>
                    ),
                    description: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.description}</Title>
                        </div>
                      </>
                    ),
                     
                    assigned_to_name: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.assigned_to_name}  </Title>
                        </div>
                      </>
                    ),


                    remark_by_assignee: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.remark_by_assignee}  </Title>
                        </div>
                      </>
                    ),

                    date_completion: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.date_completion && user.date_completion != "0000-00-00" ? moment(user.date_completion).format('DD MMMM YYYY') : '-'}</Title>

                        </div>
                      </>
                    ),
                    remark_by_staff: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.remark_by_staff}  </Title>
                        </div>
                      </>
                    ),
                    
                    status: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.status}  </Title>
                        </div>
                      </>
                    ),
                    
                 

                    createdAt: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.createdAt && user.createdAt != "0000-00-00" ? moment(user.createdAt).format('DD MMMM YYYY') : '-'}</Title>

                        </div>
                      </>
                    ),
                    action: (
                      <div className="button-container" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>

                       {hasPermission('/work_managment', 'editPermission') && (
                        <Button onClick={() => AddUserDetails(user.id)} className="update-btn">
                          <EditOutlined />
                        </Button>
                        )}

                        {hasPermission('/work_managment', 'deletePermission') && (

                        <Button onClick={() => showDeleteConfirm(user.id)} className="delete-btn">
                          <DeleteOutlined />
                        </Button>
                         )}  
                      </div>
                    )
                  };
                })}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel} 
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>

        

      <Drawer
        title="Work Managment Details"
        width={1000}
        onClose={AddUserDetailsClose}
        open={AddUserDetailsFormModel}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        maskClosable={false}
      >
        <AddUserDetailsModel userId={userId} />
      </Drawer>
    </div>
  );
}


export default Inquiry;
