import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    Button,
    Typography,
    notification,
    Form,
    Input,
    Select,Checkbox
} from "antd";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import { getPermissions, hasPermission } from '../../components/permissions';

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

function RoleForm() {
    const { id } = useParams(); // Extract id from URL
    const history = useHistory();
    const [form] = Form.useForm();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [permissionList, setPermissionList] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState({});

    useEffect(() => {
        if (id) {
            setIsUpdateMode(true);
            fetchRoleDetails();
        }  

        fetchPermission();
        // Check if id exists to determine if it's an update mode
    }, [id]);

    const fetchPermission = async () => {
        try {
            const response = await axios.get(`${baseUrl}/permission/list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });

            if (response.status === 200) {
                console.log("response.data", response.data.data.read);
                setPermissionList(response.data.data);
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const fetchRoleDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/role/read/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
            if (response.status === 200) {
                form.setFieldsValue(response.data.result);
                setSelectedPermissions(response.data.result.permissions || {});
            } else {
                notification.info({ message: 'Info', description: response.data.message, placement: 'topRight' });
            }
        } catch (error) {
            notification.info({ message: 'Info', description: error.response?.data?.message, placement: 'topRight' });
        }
    };

    const handlePermissionChange = (module, permission) => {
        setSelectedPermissions(prev => ({
            ...prev,
            [module]: { ...prev[module], [permission]: !prev[module]?.[permission] }
        }));
    };

    const onFinish = async (values) => {
        try {
            const payload = {
                name: values.name,
                description: values.description,
                permissions: Object.keys(selectedPermissions).map(module => ({
                    module_name: module,
                    url: permissionList[module]?.url || '', // Include URL from permissionList
                    readPermission: selectedPermissions[module]?.Read ? 1 : 0,
                    createPermission: selectedPermissions[module]?.Create ? 1 : 0,
                    editPermission: selectedPermissions[module]?.Edit ? 1 : 0,
                    deletePermission: selectedPermissions[module]?.Delete ? 1 : 0,
                    downloadPermission: selectedPermissions[module]?.Download ? 1 : 0,
                }))
            };
    
            if (isUpdateMode) {
                const response = await axios.patch(`${baseUrl}/role/update/${id}`, payload, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    },
                });
                notification.success({
                    message: 'Success',
                    description: 'Role updated successfully!',
                    placement: 'topRight'
                });
            } else {
                const response = await axios.post(`${baseUrl}/role/create`, payload, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    },
                });
                notification.success({
                    message: 'Success',
                    description: 'Role added successfully!',
                    placement: 'topRight'
                });
                form.resetFields();
                history.push('/role');
            }
    
        } catch (error) {
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };
    
    

    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card bordered={false} title={isUpdateMode ? "Update Role" : "Add Role"}>
                        <Form form={form} onFinish={onFinish} layout="vertical">
                            <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Role Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please enter role name' }]}
                                    >
                                        <Input placeholder="Enter role name" />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Description"
                                        name="description"
                                        rules={[{ required: true, message: 'Please enter description' }]}
                                    >
                                        <Input.TextArea placeholder="Enter description" />
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Title level={5}>Permissions</Title>
                                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                <tr>
                    <th style={{ textAlign: 'left', padding: '10px', borderBottom: '1px solid #ddd' }}>Module</th>
                   
                    <th style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>Read/View</th>
                    <th style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>Edit/Send/Check</th>
                    <th style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>Create/Add</th>
                    <th style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>Delete</th>
                    <th style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>Download</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(permissionList).map(module => (
                    <tr key={module}>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{module}

                               {/* Hidden URL input */}
                               <input
                                type="hidden"
                                value={permissionList[module].url}
                                name={`url-${module}`}
                            />
                            
                        </td>
                     
                         
                      
                         
                        {['Read', 'Edit', 'Create', 'Delete','Download'].map(permission => (
                            <td key={permission} style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>
                                <Checkbox
                                    checked={selectedPermissions[module]?.[permission] || false}
                                    onChange={() => handlePermissionChange(module, permission)}
                                />
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
</table>

                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} lg={8}>
                                    <Item>
                                        <Button type="primary" htmlType="submit"> {isUpdateMode ? "Update" : "Add"} </Button>
                                    </Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default RoleForm;