import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Table,Spin, Button, Input, Divider, notification, Modal, Select, Avatar, Typography } from "antd";
import axios from "axios";
import { baseUrl } from "../../config";
import { FileExcelOutlined, EyeOutlined, UserOutlined, SaveOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';
import * as XLSX from 'xlsx';
import 'antd/dist/antd.css';

const { Option } = Select;
const { Title } = Typography;

function CallingReport() {
  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false); // State to manage the loade
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    phone: '',
    father_name: '',
    caste: '',
    gotra: '',
    village: '',
    district: '',
    announced_amount: '',
    max_announced_amount: '',
    start_joining_date: '',
    end_joining_date: '',
  });
  const [isResponseModalVisible, setIsResponseModalVisible] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [responsePagination, setResponsePagination] = useState({});
  const [currentUserId, setCurrentUserId] = useState(null);

  useEffect(() => {
    getUserList();
  }, []);

  const createStyledTitle = (text) => (
    <span style={{ fontSize: '15px', color: '#0056b3' }}>{text}</span>
  );

  const columns = [
    {
      title: createStyledTitle("SR No."),
      dataIndex: "key",
      key: "key",
      fixed: 'left',
      width: 50,
    },
    {
      title: createStyledTitle("Name"),
      dataIndex: "name",
      key: "name",
      fixed: 'left',
      width: 200,
    },
    {
      title: createStyledTitle("Phone no"),
      dataIndex: "phone_no",
      key: "phone_no",
    },
    {
      title: createStyledTitle("Father Name"),
      dataIndex: "father_name",
      key: "father_name",
    },
    {
      title: createStyledTitle("Last Response"),
      key: "latest_payment_response",
      dataIndex: "latest_payment_response",
      width: 300,
      render: (text, record) => (
        isEditing(record.key) ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input.TextArea
              value={editingResponse}
              onChange={(e) => setEditingResponse(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, text.user_id)}
              style={{ marginRight: 8, width: '300px', height: '60px' }} // Adjust width and height as needed
            />
            <Button
              type="primary"
              onClick={() => saveResponse(text.user_id)}
              style={{ marginRight: 8 }}
            ><SaveOutlined /></Button>
            <Button
              type="default"
              onClick={() => cancelEdit()}
            ><CloseOutlined /></Button>
          </div>
        ) : (
          <div
            onDoubleClick={() => editResponse(record.key, text.latest_payment_response || '')}
            style={{ cursor: 'pointer', position: 'relative' }}
          >
            {text.latest_payment_response || '-'}
            <EditOutlined onClick={() => editResponse(record.key, text.latest_payment_response || '')} style={{ position: 'absolute', right: 0, top: 0, opacity: 0.5 }} />
          </div>
        )
      )
    },
    {
      title: createStyledTitle("Village"),
      dataIndex: "per_village",
      key: "per_village",
    },
    {
      title: createStyledTitle("District"),
      dataIndex: "per_district",
      key: "per_district",
    },
    {
      title: createStyledTitle("Occupation"),
      key: "occ_name",
      dataIndex: "occ_name",
    },
    {
      title: createStyledTitle("Monthly Share"),
      key: "announced_amount",
      dataIndex: "announced_amount",
    },
    {
      title: createStyledTitle("Joining date"),
      key: "joining_date",
      dataIndex: "joining_date",
    },  
    {
      title: createStyledTitle("DOB"),
      key: "dob",
      dataIndex: "dob",
    },
    {
      title: createStyledTitle("Gotra"),
      key: "gotra",
      dataIndex: "gotra",
    },
    {
      title: createStyledTitle("Last Payment Date"),
      key: "last_payment_date",
      dataIndex: "last_payment_date",
    },
    {
      title: createStyledTitle("Last Payment"),
      key: "last_payment_amount",
      dataIndex: "last_payment_amount", 
    },
    {
      title: createStyledTitle("Total Received Amount"),
      key: "total_received_amount",
      dataIndex: "total_received_amount",
    },
    {
      title: createStyledTitle("Current year ( duration )"),
      key: "current_year_duration",
      dataIndex: "current_year_duration",
    },
    {
      title: createStyledTitle("Total Duration"),
      key: "total_duration",
      dataIndex: "total_duration",
    },
    {
      title: createStyledTitle("Up to Month"),
      key: "end_month",
      dataIndex: "end_month",
    },
    {
      title: createStyledTitle("Status"),
      key: "member_status",
      dataIndex: "member_status",
    },
  
    {
      title: createStyledTitle("Payment Status"),
      key: "last_payment_status",
      dataIndex: "last_payment_status",
      fixed: 'right', 
    },

    
    {
      title: createStyledTitle("Action"),
      key: "action",
      dataIndex: "action",
      fixed: 'right',
    }
  ];

  const [editingKey, setEditingKey] = useState('');
  const [editingResponse, setEditingResponse] = useState('');
  const [editingResponseForBackup, setEditingResponseForBackup] = useState('');

  const isEditing = (key) => key === editingKey;

  const editResponse = (key, text) => {
    setEditingKey(key);
    setEditingResponse(text);
    setEditingResponseForBackup(text);
  };

  const cancelEdit = () => {
    setEditingKey('');
    setEditingResponse('');
    setEditingResponseForBackup('');
  };

  const saveResponse = async (key) => {
    try {
      if (editingResponse === editingResponseForBackup) {
        setEditingKey('');
        setEditingResponse('');
        setEditingResponseForBackup('');
      } else {
        const response = await axios.put(`${baseUrl}/users/updateResponse`, {
          user_id: key,
          latest_payment_response: editingResponse
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        });
        if (response.data.status) {
          notification.success({
            message: 'Success',
            description: 'Response updated successfully',
            placement: 'topRight'
          });
          getUserList(currentPage, limit, sortField, sortOrder, search, filters);
        } else {
          notification.error({
            message: 'Error',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      }
    } catch (error) {
      console.error("API error:", error);
      notification.error({
        message: 'Error',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }

    setEditingKey('');
    setEditingResponse('');
    setEditingResponseForBackup('');
  };

  const handleKeyDown = (e, userId) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior (e.g., new line in textarea)
      saveResponse(userId);
    }
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  async function getUserList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/users/callingList?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };

  const handleSearch = value => {
    setSearch(value);
    getUserList(currentPage, limit, sortField, sortOrder, value, filters);
  };

  const handleLimitChange = value => {
    setLimit(value);
    getUserList(1, value, sortField, sortOrder, search, filters);
  };

  const debouncedGetUserList = useCallback(debounce((page, limit, sortField, sortOrder, search, filters) => {
    getUserList(page, limit, sortField, sortOrder, search, filters);
  }, 300), []);

  useEffect(() => {
    const filterKeys = Object.keys(filters);

    // Check if any filter other than 'createdBy' has a length of 3 or more
    const anyFilterHasMinLength = filterKeys.some(
      key => key !== 'createdBy' && filters[key].length >= 1
    );

    // Check if all filters are either empty or specifically 'createdBy'
    const allFiltersEmptyOrCreatedBy = filterKeys.every(
      key => key === 'createdBy' || !filters[key]
    );

    if (anyFilterHasMinLength || allFiltersEmptyOrCreatedBy) {
      debouncedGetUserList(currentPage, limit, sortField, sortOrder, search, filters);
    }
  }, [filters, currentPage, limit, sortField, sortOrder, search]);

  const handleFilterChange = (field, value) => {
    setFilters({
      ...filters,
      [field]: value
    });
  };

  const exportToExcel = async () => {
    setLoading(true);
    const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const filterParams = Object.keys(filters)
      .map(key => `${key}=${filters[key]}`)
      .join('&');
    const response = await axios.get(`${baseUrl}/users/callingList?page=${1}&limit=all${sortParam}${searchParam}&${filterParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    });
    if (response.data.status) {
      setLoading(false);
      const formattedData = response.data.result.map((user, index) => ({
        "Sr.No": index + 1, 
        "Phone no": user.phone_no, 
        "Name": user.name,
        "Relation": user.relation,
        "Father Name": user.father_name,
        "Village": user.per_village,
        "Tehsil": user.per_tahsil,
        "District": user.per_district,
        "Occupation": user.occ_name,
        "Occ District": user.occ_district,
        "Joining date": user.joining_date ? moment(user.joining_date).format('DD-MM-YYYY') : '-',
        "Monthly Share": user.announced_amount,       
        "DOB": user.dob ? moment(user.dob).format('DD-MM-YYYY') : '-',
        "Gotra": user.gotra,
        "Total Duration": user.total_duration,
        "Current year ( duration )": user.current_year_duration,
        "Last Payment Date": user.last_payment_date ? moment(user.last_payment_date).format('DD-MM-YYYY') : '-',
        "Last Payment Amount": user.last_payment_amount,  
        "Up to month": user.end_month ? moment(user.end_month).format('MMMM-YYYY') : '-',    
        "Last Response": user.latest_payment_response,       
        "Total Received Amount": user.total_received_amount,  
        "Status": user.member_status,
        "Payment Status": user.last_payment_status,

      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Calling Report");
      XLSX.writeFile(workbook, "Calling-Report.xlsx");
    } else {
      setLoading(false);
      notification.info({
        message: 'Info',
        description: response.data.message,
        placement: 'topRight'
      });
    }
  };

  // For show image on model large
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const showModal = (image) => {
    setCurrentImage(image || userDummy);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchUserResponses = async (userId, page = 1, limit = 10) => {
    try {
      const response = await axios.get(`${baseUrl}/users/get_last_response/${userId}?page=${page}&limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setResponseData(response.data.result);
        setResponsePagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.error({
        message: 'Error',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const showResponseModal = (userId) => {
    setCurrentUserId(userId);
    fetchUserResponses(userId);
    setIsResponseModalVisible(true);
  };

  const handleResponseModalCancel = () => {
    setIsResponseModalVisible(false);
  };


  return (
<>
    {
      loading && (
        <div className="full-page-loader">
          <Spin size="large" />
        </div>
      )
    }
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Calling Module"
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}><Title style={{ fontSize: 13 }} level={5}>Filter By:</Title></Col>
                      <Col span={4}>
                        <Input placeholder="Phone" value={filters.phone} onChange={e => handleFilterChange('phone', e.target.value)} />
                      </Col>
                      <Col span={4}>
                        <Input placeholder="Name" value={filters.name} onChange={e => handleFilterChange('name', e.target.value)} />
                      </Col>
                      <Col span={4}>
                        <Input placeholder="Father Name" value={filters.father_name} onChange={e => handleFilterChange('father_name', e.target.value)} />
                      </Col>
                      <Col span={3}>
                        <Input placeholder="Caste" value={filters.caste} onChange={e => handleFilterChange('caste', e.target.value)} />
                      </Col>
                      <Col span={3}>
                        <Input placeholder="Gotra" value={filters.gotra} onChange={e => handleFilterChange('gotra', e.target.value)} />
                      </Col>
                      <Col span={3}>
                        <Input placeholder="Village" value={filters.village} onChange={e => handleFilterChange('village', e.target.value)} />
                      </Col>
                      <Col span={3}>
                        <Input placeholder="District" value={filters.district} onChange={e => handleFilterChange('district', e.target.value)} />
                      </Col>
                      <Col span={8}>
                        <div className="amount-range-input">
                          <Input
                            placeholder="Min Monthly Share"
                            value={filters.announced_amount}
                            onChange={e => handleFilterChange('announced_amount', e.target.value)}
                            style={{ marginBottom: '8px' }}
                          />
                          <span className="range-separator">-</span>
                          <Input
                            placeholder="Max Monthly Share"
                            value={filters.max_announced_amount}
                            onChange={e => handleFilterChange('max_announced_amount', e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="amount-range-input">
                          <Input
                            placeholder="Joining Day"
                            value={filters.start_joining_date}
                            onChange={e => handleFilterChange('start_joining_date', e.target.value)}
                            style={{ marginBottom: '8px' }}
                          />
                          <span className="range-separator">-</span>
                          <Input
                            placeholder="Joining Day"
                            value={filters.end_joining_date}
                            onChange={e => handleFilterChange('end_joining_date', e.target.value)}
                          />
                        </div>
                      </Col>

                     

                      <Divider />
                      <Row gutter={16} style={{ marginBottom: '16px', textAlign: 'right' }}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                          {/* <Button type="primary" icon={<SearchOutlined />} >
                            Search Report
                          </Button> */}
                          {hasPermission('/reports/calling-report', 'downloadPermission') && (
                          <Button type="default" icon={<FileExcelOutlined />} onClick={exportToExcel}>
                            Excel Download
                          </Button>
                          )}
                        </Col>
                      </Row>
                      <Divider />
                      <Col xs={24}>
                        <span style={{ marginRight: 10 }}>Records per page:</span>
                        <Select defaultValue={20} onChange={handleLimitChange}>
                          <Option value={20}>20</Option>
                          <Option value={40}>40</Option>
                          <Option value={60}>60</Option>
                          <Option value={80}>80</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )}
                dataSource={userList.map((user, index) => ({
                  key: (limit * (currentPage - 1)) + index + 1,
                  name: (
                    <>
                      <Avatar.Group>
                        <Avatar
                          className="shape-avatar"
                          shape="square"
                          size={40}
                          src={user.profile_img ? user.profile_img : userDummy}
                          icon={!user.profile_img && <UserOutlined />}
                          onClick={() => showModal(user.profile_img ? user.profile_img : userDummy)}
                          style={{ cursor: 'pointer' }}
                        />
                        <div className="avatar-info">
                          <Title level={5}>
                            <Link to={`/users/view/${user.user_id}`}  >
                              {user.name}
                            </Link>
                          </Title>
                        </div>
                      </Avatar.Group>{" "}
                    </>
                  ),
                  phone_no: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.phone_no}</Title>
                      </div>
                    </>
                  ),
                  father_name: (
                    <>
                      <div className="author-info">
                        <Title level={5}> {user.relation_en && user.relation_hn ? `${user.relation_en} / ${user.relation_hn}` : user.relation_en || user.relation_hn || ''}</Title>
                        <p>
                          {user.father_name}
                        </p>
                      </div>
                    </>
                  ),
                  per_village: user.per_village,
                  per_district: user.per_district,
                  occ_name: user.occ_name,
                  announced_amount: (
                    <>
                      <div className="author-info">
                        <Title level={5}>Rs.{user.announced_amount ? user.announced_amount : 0}</Title>
                      </div>
                    </>
                  ),
                  joining_date: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.joining_date ? moment(user.joining_date).format('DD/MMM/YYYY') : '-'}  </Title>
                      </div>
                    </>
                  ),
                  dob: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.dob ? moment(user.dob).format('DD MMMM YYYY') : '-'}</Title>
                      </div>
                    </>
                  ),
                  gotra: user.gotra,
                  latest_payment_response: user,
                  current_year_duration: user.current_year_duration,
                  total_duration: user.total_duration,
                  end_month: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.end_month ? moment(user.end_month).format('MMMM-YYYY') : '-'}</Title>
                      </div>
                    </>
                  ),

                  
                  last_payment_date: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.last_payment_date ? moment(user.last_payment_date).format('DD-MM-YYYY') : '-'}</Title>
                      </div>
                    </>
                  ),
                  last_payment_amount: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.last_payment_amount ? 'Rs.'+user.last_payment_amount : '-' }</Title>
                      </div>
                    </>
                  ), 

                  total_received_amount: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.total_received_amount ? 'Rs.'+user.total_received_amount : '-' }</Title>
                      </div>
                    </>
                  ), 
                  member_status: user.member_status,

                  last_payment_status: (
                    <div className="button-container">
                      {(user.last_payment_status === 'pending' || user.last_payment_status === 'failed') && (
                        <Button
                          type="primary"
                          style={{ backgroundColor: '#faad14', color: '#fff', borderColor: '#faad14' }}
                          danger={user.last_payment_status === 'failed'}
                           
                        >
                          {user.last_payment_status === 'pending' ? 'Pending' : 'Failed '}
                        </Button>
                      )}
                      {user.last_payment_status === 'verified' && (
                        <Button type="primary" style={{ backgroundColor: 'green', color: '#fff', borderColor: 'green' }}>
                          Verified
                        </Button>
                      )}
                    </div>
                  ),



                  action: (
                    <div className="button-container">
                      {hasPermission('/reports/calling-report', 'editPermission') && (
                        <button className="update-btn">
                          <EyeOutlined onClick={() => showResponseModal(user.user_id)} />
                        </button>
                      )}
                    </div>
                  )
                }))}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>

      <Modal
        open={isResponseModalVisible}
        footer={null}
        onCancel={handleResponseModalCancel}
        width={800}
      >
        <Table
          columns={[
            {
              title: 'Response',
              dataIndex: 'response',
              key: 'response',
              width: 500,
              render: (text) => <div>{text}</div>
            },
            {
              title: 'Date',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: (text) => <div>{moment(text).format('DD-MM-YYYY')}</div>
            }
          ]}
          dataSource={responseData}
          pagination={{
            ...responsePagination,
            onChange: (page, pageSize) => fetchUserResponses(currentUserId, page, pageSize),
          }}
          rowKey="id"
        />
      </Modal>

    </div>
    </>
  );
}

export default CallingReport;
