import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Typography,
  notification,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Space,
  Upload
} from "antd";
import axios from "axios";
import { UserOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

function VisheshSahyogiForm({ userId, onRefreshData = () => {} }) {


  // const { id } = useParams(); // Extract id from URL
  const history = useHistory();
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [txnDate, setTxnDate] = useState();

  const [meetingDate, setMeetingDate] = useState();
  const [imageUrl, setImageUrl] = useState(null); // Store the image URL if already uploaded
  const [fileList, setFileList] = useState([]); // Store the image file list for Upload
 



  useEffect(() => {
    // Check if id exists to determine if it's an update mode
    if (userId) {
      setIsUpdateMode(true);
      fetchRoleDetails();
    }
  }, [userId]);



  const fetchRoleDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/vishesh_sahyogi/read/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {
        // setRoleData(response.data.result);
        form.setFieldsValue(response.data.result); // Populate form fields with fetched data
        setTxnDate(response.data.result.dob ? response.data.result.dob : null) // Format the date if present)
        setMeetingDate(response.data.result.meeting_date ? response.data.result.meeting_date : null) // Format the date if present)
         
        if (response.data.result.profile_photo) {
          setImageUrl(response.data.result.profile_photo);
          setFileList([
            {
              uid: '-1',
              name: 'Uploaded Image',
              status: 'done',
              url: response.data.result.profile_photo,
            },
          ]);
        }

      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

   

  const onFinish = async (values) => {
    try {
      const formData = new FormData(); // Create a FormData object to handle files and form data

      // Format the dates
      if (txnDate) {
        const formattedDate = moment(txnDate).format('YYYY-MM-DD');
        formData.append('dob', formattedDate);
      } else {
        formData.append('dob', '');
      }

      if (meetingDate) {
        const formattedMeetingDate = moment(meetingDate).format('YYYY-MM-DD');
        formData.append('meeting_date', formattedMeetingDate);
      } else {
        formData.append('meeting_date', '');
      }

      // Append other form fields to formData
      formData.append('phone_no', values.phone_no);
      formData.append('order_key', values.order_key);
      formData.append('name', values.name);
      formData.append('father_name', values.father_name);
      formData.append('father_in_low', values.father_in_low);
      formData.append('gotra', values.gotra);
      formData.append('village', values.village);
      formData.append('tehsil', values.tehsil);
      formData.append('district', values.district);
      formData.append('occupation', values.occupation);
      formData.append('occ_district', values.occ_district);
      formData.append('inspiration_remark', values.inspiration_remark);
      formData.append('amount_pledge', values.amount_pledge);
      formData.append('alternative_no', values.alternative_no);
      formData.append('committee', values.committee);
      formData.append('meeting_attendance', values.meeting_attendance);
      formData.append('meeting_remark', values.meeting_remark);

      // Append action type
      // formData.append('action', sendMessage ? 'save_send' : 'save');

      // Append image (if any)
      if (values.image && values.image.length > 0 && values.image[0].originFileObj) {
        formData.append('file', values.image[0].originFileObj); // Append the image file
      }

      // Make the API request (either POST for create or PATCH for update)
      let response;
      if (isUpdateMode) {
        response = await axios.patch(`${baseUrl}/vishesh_sahyogi/update/${userId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            'Content-Type': 'multipart/form-data' // Ensure multipart content type for FormData
          },
        });
      } else {
        response = await axios.post(`${baseUrl}/vishesh_sahyogi/create`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            'Content-Type': 'multipart/form-data' // Ensure multipart content type for FormData
          },
        });
      }

      // Handle success or error response
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: isUpdateMode ? 'Record updated successfully!' : 'Vishesh Sahyogi added successfully!',
          placement: 'topRight'
        });
        onRefreshData();
        if (!isUpdateMode) {
          form.resetFields(); // Reset form for new entry
          setTxnDate(''); // Clear dates
          setMeetingDate('');
          // getUserList(); // Refresh the list
        }
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message || 'An error occurred',
        placement: 'topRight'
      });
    }
  };



  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setTxnDate(formattedDate)
    } else {
      setTxnDate(null);
    }
  };

  const handleMeetingDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setMeetingDate(formattedDate)
    } else {
      setMeetingDate(null);
    }
  };



  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={isUpdateMode ? "Update Vishesh Sahyogi" : "Add Vishesh Sahyogi"}
          > 
            <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
              <Row gutter={[16, 16]}>

                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Upload Image"
                    name="image"
                    valuePropName="file"
                    rules={[{ required: false, message: 'Please upload an image' }]}
                  > 
                    <Upload
                      name="image"
                      listType="picture"
                      accept="image/*"
                      beforeUpload={() => false} // Prevent auto-upload, so we can manually handle it
                      onChange={({ fileList: newFileList }) => {
                        setFileList(newFileList); // Update the file list when a new file is selected
                        form.setFieldsValue({ image: newFileList }); // Store the file list in form fields
                      }}
                      fileList={fileList} // Use fileList state to control the display
                      maxCount={1} // Restrict to a single image
                    >
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>



                  </Item> 
                </Col>

                <Col xs={24} sm={4} lg={4}>
                  <Item
                    label="Phone No"
                    name="phone_no"
                    rules={[{ required: true, message: 'Please Enter  Phone no' },
                    {
                      pattern: /^\d{10,12}$/,
                      message: 'Phone no must be between 10 and 12 digits!',
                    }
                    ]}
                  >

                    <Input type="number" placeholder="Enter Phone no" />
                  </Item>
                </Col>

                <Col xs={24} sm={4} lg={4}>
                  <Item
                    label="Order no"
                    name="order_key"
                  >
                    <Input type="number" placeholder="Enter Order No" />
                  </Item>
                </Col>

                <Col xs={24} sm={4} lg={4}>
                  <Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please Enter Name' }]}
                  >
                    <Input placeholder="Enter Name" />
                  </Item>
                </Col>
                <Col xs={24} sm={6} lg={4}>
                  <Item
                    label="Father Name"
                    name="father_name"
                  >
                    <Input placeholder="Enter Father Name" />
                  </Item>
                </Col>

                <Col xs={24} sm={6} lg={4}>
                  <Item
                    label="Father in law"
                    name="father_in_low"
                  >
                    <Input placeholder="Enter father in law" />
                  </Item>
                </Col>

                <Col xs={24} sm={6} lg={4}>
                  <Item
                    label="Gotra"
                    name="gotra"
                  >
                    <Input placeholder="Enter Gotra Name" />
                  </Item>
                </Col>

                <Col xs={24} sm={6} lg={4}>
                  <Item
                    label="Village"
                    name="village"
                  >
                    <Input placeholder="Enter Village" />
                  </Item>
                </Col>
                <Col xs={24} sm={6} lg={4}>
                  <Item
                    label="Tehsil"
                    name="tehsil"
                  >
                    <Input placeholder="Enter Tehsil" />
                  </Item>
                </Col>

                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="District"
                    name="district"
                  >
                    <Input placeholder="Enter District" />
                  </Item>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Occupation"
                    name="occupation"
                  >
                    <Input placeholder="Enter Occupation" />
                  </Item>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Occupation District"
                    name="occ_district"
                  >
                    <Input placeholder="Enter Occupation District" />
                  </Item>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Contribution Remark"
                    name="inspiration_remark"
                  >
                    <Input placeholder="Enter Contribution Remark" />
                  </Item>
                </Col>

                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Amount"
                    name="amount_pledge"
                  >
                    <Input type="number" placeholder="Enter Amount " />
                  </Item>
                </Col>

                <Col xs={24} sm={4} lg={4}>
                  <Item label="DOB" >
                    <DatePicker onChange={handleDateChange} value={txnDate && txnDate != "0000-00-00" && moment(txnDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select DOB" prefix={<CalendarOutlined />} />

                  </Item>
                </Col>

                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Alternative No"
                    name="alternative_no"
                  >
                    <Input type="number" placeholder="Enter Alternative No" />
                  </Item>
                </Col>

                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Committee"
                    name="committee"
                  >
                    <Input placeholder="Enter Committee" />
                  </Item>
                </Col>

                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Meeting Attendance"
                    name="meeting_attendance"
                  >
                    <Input placeholder="Enter Meeting Attendance" />
                  </Item>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Meeting Remark "
                    name="meeting_remark"
                  >
                    <Input placeholder="Enter Meeting Remark" />
                  </Item>
                </Col>



                <Col xs={24} sm={4} lg={4}>
                  <Item label="Meeting Date" >
                    <DatePicker onChange={handleMeetingDateChange} value={meetingDate && meetingDate != "0000-00-00" && moment(meetingDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select DOB" prefix={<CalendarOutlined />} />

                  </Item>
                </Col>




              </Row>
              <Row style={{ textAlign: 'right' }}>
                <Col xs={24} sm={24} lg={24}>
                  <Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: '10px' }} // Add space between buttons
                    >
                      {isUpdateMode ? "Update" : "Save"}
                    </Button>


                  </Item>
                </Col>
              </Row>

            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default VisheshSahyogiForm;
